// Hooks / Node modules / Styles

import { motion } from "framer-motion";
import { textVariant, fadeIn } from "../../../utils/motion";
import DefaultLayout from "../../../layouts/DefaultLayout";

// Reducers / Actions

// Utils / Functions

// Components
import { Button } from "../../../components";

// Sub-Components

// Data / Images / Icons

function VisionMissionAndValue() {
  return (
    <DefaultLayout>
      <div className="">
        <motion.p
          variants={fadeIn("", "", 0.1, 1)}
          className="padding-x padding-y text-section text-center"
        >
          At Incbotic, we are architects of the future, creators of cutting-edge
          solutions that bridge the gap between imagination and reality. With a
          passion for innovation and a commitment to excellence, we are a
          dynamic force in the world of electronic and software design.
        </motion.p>

        <div className="padding-x padding-y bg-boxes flex flex-col items-center justify-center">
          <motion.div
            variants={textVariant()}
            className="text-chapter text-gray-600"
          >
            Our Vision
          </motion.div>
          <motion.p variants={fadeIn("", "", 0.1, 1)} className="text-section">
            Building Possibilities, Enriching Lives
          </motion.p>
        </div>
        <motion.p
          variants={fadeIn("", "", 0.1, 1)}
          className="padding-x padding-y text-section text-center"
        >
          Our journey began with a simple yet powerful vision: to harness
          technology's potential to shape a better tomorrow. Guided by this
          vision, we embark on each project with enthusiasm, determination, and
          an unwavering focus on delivering solutions that enrich lives, empower
          businesses, and transform industries.
        </motion.p>

        <div className="padding-x padding-y flex flex-col items-center justify-center">
          <motion.div
            variants={textVariant()}
            className="text-chapter text-gray-600"
          >
            Why Choose Incbotic?
          </motion.div>
          <ul className="pt-5 text-section flex flex-col md:flex-row items-center justify-center gap-4 md:gap-10 text-gray-700">
            <li>Electrifying Expertise</li>
            <li className="hidden md:block text-gray-400">|</li>
            <li>Innovation in Every Byte</li>
            <li className="hidden md:block text-gray-400">|</li>
            <li>End-to-End Excellence</li>
          </ul>
        </div>

        <div className="flex flex-row md:flex-col gap-5 items-center justify-center bg-gray-300 py-[40px] md:py-[50px]">
          <div className="text-section">Let’s Keep in Touch</div>
          <div>
            <Button to="/contact-us" text="Contact Us" />
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default VisionMissionAndValue;
