// Hooks / Node modules / Styles
import DefaultLayout from "../../../layouts/DefaultLayout";

// Reducers / Actions

// Utils / Functions

// Components

// Sub-Components

// Data / Images / Icons

function Experience() {
  return (
    <DefaultLayout>
      <div>Experience</div>
    </DefaultLayout>
  );
}

export default Experience;
