// Hooks / Node modules / Styles
import { useState } from "react";
import { Form, Input } from "antd";
import * as yup from "yup";
import UploadForm from "../../../../../../utils/helpers/UploadForm";
import { uploadBOM } from "../../../../../../api/api";

// Reducers / Actions

// Utils / Functions

// Components
import { Button } from "../../../../../../components";

// Sub-Components
import BOMUploadArea from "./file-uploads/BOMUploadArea";

// Data / Images / Icons
import bom_template from "../../../../../../data/JLCPCB_BOM_Template.xls";
const INITIAL_DATA = {};

let schema = yup.object().shape({
  bom: yup
    .mixed()
    .test(
      "isBom",
      "Invalid file format. Please upload a Gerber file.",
      (value: any) => {
        if (!value) return true; // Allow an empty value
        return (
          // MIME types for XML based xls files
          value[0]?.type === "application/vnd.ms-excel" ||
          value[0]?.type === "application/x-msexcel" ||
          value[0]?.type === "application/x-excel" ||
          value[0]?.type === "application/x-dos_ms_excel" ||
          // MIME types for XML based xlsx files
          value[0]?.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          value[0]?.type === "application/vnd.ms-excel.sheet.macroEnabled.12" ||
          value[0]?.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.template" ||
          value[0]?.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.template.macroEnabled.12"
        );
      }
    )
    .test(
      "fileSize",
      "File is too large. Maximum size is 10MB.",
      (value: any) => {
        if (!value) return true; // Allow an empty value
        return value[0].size <= 10 * 1024 * 1024; // 10MB
      }
    )
    .required("File is required"),
});

const yupSync = {
  async validator({ field }: any, value: any) {
    await schema.validateSyncAt(field, { [field]: value });
  },
};

function BOMForm() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async (values: any) => {
    const file: any = values.bom[0];
    if (file) {
      const formData = new FormData();
      formData.append("size", (file.size / 1e6).toString());
      formData.append("file_name", file.name);
      formData.append("file", file.originFileObj);

      await uploadBOM(formData);
      return true;
    }
    return false;
  };

  return (
    <div className="bg-bg_circuit bg-opacity-10">
      <UploadForm
        form={form}
        initialValues={INITIAL_DATA}
        name="BOMForm"
        autoComplete="off"
        layout="vertical"
        onSubmit={onSubmit}
        setIsLoading={setIsLoading}
      >
        <div className="padding-x padding-y">
          <div className="text-[35px] font-bold text-gray-600 text-center pb-5 md:pb-[40px]">
            Upload File
          </div>
          <div className="pb-5">
            <BOMUploadArea rules={[yupSync]} />
            <div className="text-gray-500 font-semibold">
              Not sure where to start?{" "}
              <a href={bom_template} className="text-blue-500">
                Download a BOM Template.
              </a>
            </div>
          </div>

          <Button
            text="Submit"
            htmlType="submit"
            customStyles="w-full md:w-[200px] text-lg font-semibold py-7 rounded-lg mx-auto"
            isLoading={isLoading}
          />
        </div>
      </UploadForm>
    </div>
  );
}

export default BOMForm;
