import jwt_decode from "jwt-decode";
import { notification } from "antd";
import { signInWIthCode, refreshToken as refreshTokenAPI } from "../api/api";

interface TokenResponse {
  access_token: string;
  refresh_token: string;
}

class AuthImpl {
  listeners: ((state: boolean) => void)[] = [];
  token?: string;
  refreshToken?: string;

  constructor() {
    this.token = localStorage.getItem("accessToken") || undefined;
    this.refreshToken = localStorage.getItem("refreshToken") || undefined;
  }

  async signInWithToken(authorizationCode: string, redirectURI: string) {
    try {
      const { access_token, refresh_token } = await signInWIthCode(
        authorizationCode,
        redirectURI
      );
      this.setTokens(access_token, refresh_token);
      this.notify();
      return true;
    } catch (e) {
      this.clearTokens();
      return false;
    }
  }

  async getToken() {
    if (this.token) {
      const data: any = jwt_decode(this.token);
      const now = new Date();
      if (now.getTime() + 1 >= (data.exp - 2) * 1000) {
        return await this.fetchTokenFromRefreshToken();
      } else return this.token;
    } else if (this.refreshToken && this.refreshToken.length > 0) {
      return await this.fetchTokenFromRefreshToken();
    }
    notification.error({ message: "Not Signed In" });
    return Promise.reject("Not Signed In");
  }

  async fetchTokenFromRefreshToken() {
    if (this.refreshToken) {
      try {
        const { refresh_token = "", access_token } =
          (await refreshTokenAPI(this.refreshToken)) || {};
        this.setTokens(access_token, refresh_token);
        return access_token;
      } catch (error) {
        this.clearTokens();
        throw error;
      }
    }
  }

  isSignedIn() {
    return this.token !== undefined;
  }

  subscribe(listener: (state: boolean) => void): void {
    this.listeners.push(listener);
  }

  notify() {
    this.listeners.forEach((o) => {
      o(this.token !== undefined);
    });
  }

  setTokens(accessToken: string, refreshToken: string) {
    this.token = accessToken;
    this.refreshToken = refreshToken;
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
  }

  clearTokens() {
    this.token = undefined;
    this.refreshToken = undefined;
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    this.notify();
  }

  signOut() {
    this.clearTokens();
  }
}

export const Auth = new AuthImpl();
