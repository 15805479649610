// Configurations
// import { ROLES } from "../config/roles";
import { ROUTE_TYPES } from "../config/routeTypes";
import { ROLES } from "../config/roles";

import Pages from "../pages";

const ROUTES = [
  // Home
  {
    path: "/",
    name: "Home",
    component: Pages.Home,
    type: ROUTE_TYPES.Public,
  },

  //   Authentication
  {
    path: "/login",
    name: "Login",
    component: Pages.Login,
    type: ROUTE_TYPES.Public,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Pages.Signup,
    type: ROUTE_TYPES.Public,
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: Pages.ForgotPassword,
    type: ROUTE_TYPES.Public,
  },

  //   Service
  // Hardware
  {
    path: "/service/hardware/custom-solutions",
    name: "Custom Solutions",
    component: Pages.CustomSolutions,
    type: ROUTE_TYPES.Public,
  },
  {
    path: "/service/hardware/pcb-design",
    name: "PCB Design",
    component: Pages.PcbDesign,
    type: ROUTE_TYPES.Public,
  },
  {
    path: "/service/hardware/pcb-fabrication",
    name: "PCB Fabrication",
    component: Pages.PcbFabrication,
    type: ROUTE_TYPES.Public,
  },
  {
    path: "/service/hardware/pcb-assembly",
    name: "PCB Assembly",
    component: Pages.PcbAssmebly,
    type: ROUTE_TYPES.Public,
  },
  {
    path: "/service/hardware/order-history",
    name: "Order History",
    component: Pages.OrderHistory,
    type: ROUTE_TYPES.Public,
  },
  {
    path: "/service/hardware/bom-service",
    name: "BOM Service",
    component: Pages.BomService,
    type: ROUTE_TYPES.Public,
  },
  // Software
  {
    path: "/service/software/web-solutions",
    name: "Web Solutions",
    component: Pages.WebSolutions,
    type: ROUTE_TYPES.Public,
  },
  {
    path: "/service/software/cloud-solutions",
    name: "Cloud Solutions",
    component: Pages.CloudSolutions,
    type: ROUTE_TYPES.Public,
  },
  // IoT
  {
    path: "/service/iot/iot-solutions",
    name: "IoT Solutions",
    component: Pages.IoTSolutions,
    type: ROUTE_TYPES.Public,
  },
  {
    path: "/service/iot/large-scale-manufacturing",
    name: "IoT Solutions",
    component: Pages.LargeScaleManufacturing,
    type: ROUTE_TYPES.Public,
  },

  // Products
  {
    path: "/products/smart-product",
    name: "Smart Product",
    component: Pages.SmartProduct,
    type: ROUTE_TYPES.Public,
  },
  {
    path: "/products/experience",
    name: "Experience",
    component: Pages.Experience,
    type: ROUTE_TYPES.Public,
  },
  {
    path: "/products/smart-life",
    name: "Smart Life",
    component: Pages.SmartLife,
    type: ROUTE_TYPES.Public,
  },

  // Careers
  {
    path: "/careers",
    name: "Careers",
    component: Pages.Careers,
    type: ROUTE_TYPES.Public,
  },

  // About Us
  {
    path: "/about-us/vision-mission-and-value",
    name: "Vission Mission and Value",
    component: Pages.VisionMissionAndValue,
    type: ROUTE_TYPES.Public,
  },
  {
    path: "/about-us/location",
    name: "Location",
    component: Pages.Location,
    type: ROUTE_TYPES.Public,
  },
  {
    path: "/terms-conditions",
    name: "Terms Conditions",
    component: Pages.Location,
    type: ROUTE_TYPES.Public,
  },

  // Contact Us
  {
    path: "/contact-us",
    name: "Contact Us",
    component: Pages.ContactUs,
    type: ROUTE_TYPES.Public,
  },

  // FAQ
  {
    path: "/faq",
    name: "FAQ",
    component: Pages.FAQ,
    type: ROUTE_TYPES.Public,
  },

  // Dashboard
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Pages.Dashboard,
    type: ROUTE_TYPES.Protected,
    allowedRoles: [ROLES.Staff, ROLES.Admin],
  },
];

export default ROUTES;
