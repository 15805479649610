import { BsRocketTakeoff } from "react-icons/bs";
import { BsGlobeAsiaAustralia } from "react-icons/bs";
import { BsFillLightbulbFill } from "react-icons/bs";
import { AiOutlineTeam } from "react-icons/ai";

import { StepList } from "../../../components";

interface IStep {
  icon?: any;
  title?: string;
  description?: string;
}

const DATA_STEPS: IStep[] = [
  {
    icon: <BsRocketTakeoff />,
    title: "Innovation at the Core",
    description:
      "Our work revolves around pushing boundaries and pioneering new solutions. Join us to be part of a team that constantly seeks to redefine what's possible.",
  },
  {
    icon: <BsGlobeAsiaAustralia />,
    title: "Global Impact",
    description:
      "Collaborate with experts from around the world to create products that impact lives on a global scale. Your contributions will resonate far beyond your workspace.",
  },
  {
    icon: <BsFillLightbulbFill />,
    title: "Continuous Learning",
    description:
      "Embrace a culture of growth and learning. With access to cutting-edge technologies and a supportive environment, you'll have the tools to expand your skills.",
  },
  {
    icon: <AiOutlineTeam />,
    title: "Team of Experts",
    description:
      "Join a team of professionals who are leaders in their respective fields. Here, you'll have the chance to learn from the best and contribute to groundbreaking projects.",
  },
];

function WhyIncbotic() {
  return (
    <div>
      <StepList title="Why Choose Incbotic?" data={DATA_STEPS} />
    </div>
  );
}

export default WhyIncbotic;
