function Description() {
  return (
    <div className="padding-x padding-y bg-gray-200 flex flex-col items-center text-center gap-8">
      <div className="text-section  text-gray-800 w-full md:w-[800px]">
        Bring your circuit to life with our PCB design service. Just send us
        your circuit design get a manufacturable PCB Gerber file, carefully
        designed by our experts to minimize signal interference, maintain
        impedance, and adhere to design rules.
      </div>
    </div>
  );
}

export default Description;
