// Hooks / Node modules / Styles
import { Form, Input, notification, Select } from "antd";
import { Link } from "react-router-dom";
import DefaultLayout from "../../layouts/DefaultLayout";
import { Button } from "../../components";

import * as yup from "yup";

import { AiOutlineMail } from "react-icons/ai";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { AiOutlineFacebook } from "react-icons/ai";
import { contactUs } from "../../api/api";

import { useEffect, useState } from "react";
import { getServices } from "../../api/api";
import {AuthContext, IAuthContext} from "../../auth/AuthContextProvider";

// Reducers / Actions

// Utils / Functions

// Components

// Sub-Components

// Data / Images / Icons

let schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  service: yup.string().required("Service is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  contactNumber: yup
    .string()
    .matches(
      /^\+94\d{9}$/,
      'Invalid phone number. It must start with "+94" and be followed by 9 digits.'
    )
    .required("Phone number is required"),
  inquiry: yup.string().required("Inquiry is required"),
});

const yupSync = {
  async validator({ field }: any, value: any) {
    await schema.validateSyncAt(field, { [field]: value });
  },
};

function ContactUs() {
  const [form] = Form.useForm();

  const [services, setServices] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getServices().then((services) => {
      setServices(
        services?.map(({ id, name }: { id: number; name: string }) => {
          return { value: id, label: name };
        }) || []
      );
    });
  }, []);

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    try {
      await contactUs(values);
      notification.info({ message: "Submitted" });
      form.resetFields();
    } catch (e: any) {
      notification.error({ message: `Failed to Submit \n ${e.message}` });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DefaultLayout>
      <div className="flex items-center justify-center">
        <div className="padding-x padding-y w-full md:w-[1000px]">
          <div className="text-chapter text-gray-700 text-center mb-7">
            Contact Us
          </div>
          <AuthContext.Consumer>{(auth:IAuthContext)=>
            <Form
              form={form}
              name="contactForm"
              layout="vertical"
              onFinish={onSubmit}
              disabled={isLoading}
              initialValues={{
                name: auth.user?.user,
                email: auth.user?.email,
                contactNumber: auth.user?.phone_number
              }}
            >
              <Form.Item name="name" label="Name" rules={[yupSync]} required>
                <Input placeholder="Please enter your name" size="large"/>
              </Form.Item>
              <Form.Item
                name="service"
                label="Service"
                rules={[yupSync]}
                required
              >
                <Select
                  // defaultValue="Custom Solutions"
                  options={services}
                  size="large"
                />
              </Form.Item>
              <Form.Item name="email" label="Email" rules={[yupSync]} required>
                <Input placeholder="Please enter your email" size="large"/>
              </Form.Item>
              <Form.Item
                name="contactNumber"
                label="Contact Number"
                rules={[yupSync]}
                required
              >
                <Input
                  placeholder="Please enter your contact number"
                  size="large"
                />
              </Form.Item>
              <Form.Item
                name="inquiry"
                label="Inquiry"
                rules={[yupSync]}
                required
              >
                <Input placeholder="Please enter inquiry" size="large" />
              </Form.Item>
              <Form.Item className="my-3">
                <Button
                  type="primary"
                  htmlType="submit"
                  text="Submit"
                  customStyles="w-full md:w-[250px] py-[25px]"
                />
              </Form.Item>
            </Form>
          }</AuthContext.Consumer>

          {/* Form Footer */}
          <div className="my-7 py-7 border-b border-b-gray-500/50 border-t border-t-gray-500/50">
            <div className="text-subsection">
              <span>Looking for a quick answer? </span>
              <Link to="/faq" className="text-blue-800 font-semibold">
                See Our FAQs
              </Link>
            </div>
          </div>

          <div className="py-5 text-subsection flex items-center justify-center">
            <div className="inline-flex gap-5">
              <div className="inline-flex gap-3 items-center">
                <AiOutlineMail /> <span>info@incbotic.com</span>
              </div>
              <div>|</div>
              <div className="inline-flex gap-3 items-center">
                <AiOutlineWhatsApp />
                <span>+94702351451</span>
              </div>
              <div>|</div>
              <div className="inline-flex gap-3 items-center">
                <AiOutlineFacebook />
                <a href="https://www.facebook.com/incbotic">Facebook</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default ContactUs;
