import { Button } from "../../../components";

function ContactUs() {
  return (
    <div className="flex flex-row md:flex-col gap-5 items-center justify-center py-[40px] md:py-[50px]">
      <div className="text-section">Let’s Keep in Touch</div>
      <div>
        <Button to="/contact-us" text="Contact Us" />
      </div>
    </div>
  );
}

export default ContactUs;
