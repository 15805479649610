import React, { useEffect, useState } from "react";
import { Form, Input, Select, Space, Checkbox } from "antd";
import RadioCustom from "../../../../../../../../components/base/customRadioGroup";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import {
  differentDesignPanelOptions,
  layersOptions,
  materialTypeOptions,
  quantityOptions,
  sizeImg,
  thicknessOptions,
  toolTips,
  trackSpaceImg,
  trackSpacingOptions,
  thicknessImg,
  minHoleSizeImg,
  holeSizeOptions,
  solderMaskOptions,
  slikScreenOptions,
  surfaceFinishOptions,
  viaProcessOptions,
  finishedCopperImg,
  surfaceFinishSelectOptions,
  FR4TgOptions,
} from "./pcbForm.constants";

interface PBCFormProps {
  form: any;
}

const PCBForm: React.FC<PBCFormProps> = ({ form }) => {
  const materialType = Form.useWatch("materialType", form);
  const layersCount = Form.useWatch("layersCount", form);
  const solderMask = Form.useWatch("solderMask", form);
  const [requirementMessage, setRequirementMessage] = useState("");

  const [isOtherSelected, setIsOtherSelected] = useState<boolean>(false);
  const [isOtherSelectedDifDesign, setIsOtherSelectedDifDesign] =
    useState<boolean>(false);
  const [isOtherSelectedThiknessn, setIsOtherSelectedTHikness] =
    useState<boolean>(false);

  const handleOtherThiknessChange = (e: CheckboxChangeEvent) => {
    setIsOtherSelectedTHikness(e.target.checked);
  };
  const handleOtherChange = (e: CheckboxChangeEvent) => {
    setIsOtherSelected(e.target.checked);
  };
  const handleOtherChangeDifDesign = (e: CheckboxChangeEvent) => {
    setIsOtherSelectedDifDesign(e.target.checked);
  };

  const thicknessValidation = (_: any, value: number) => {
    if (isOtherSelectedThiknessn) {
      if (value < 1.7 || value > 6.0) {
        return Promise.reject(
          new Error("Thickness must be between 1.7 and 6.0")
        );
      }
    }
    return Promise.resolve();
  };

  const getDisabledThicknessOptions = (layers: string | undefined) => {
    if (!layers) return [];

    const layerNumber = parseInt(layers.split(" ")[0]);
    switch (layerNumber) {
      case 4:
        return [0.2, 0.3];
      case 6:
        return [0.2, 0.3, 0.4, 0.6];
      case 8:
        return [0.2, 0.3, 0.4, 0.6, 0.8];
      case 10:
        return [0.2, 0.3, 0.4, 0.6, 0.8, 1.0];
      case 12:
        return [0.2, 0.3, 0.4, 0.6, 0.8, 1.0, 1.2];
      case 14:
        return [0.2, 0.3, 0.4, 0.6, 0.8, 1.0, 1.2, 1.6];
      default:
        return [];
    }
  };

  const getDisabledHoleSizes = () => {
    if (materialType === "Aluminum board") {
      return ["0.15mm", "0.2mm", "0.25mm", "0.3mm"];
    } else if (materialType === "FR-4") {
      return ["0.8mm", "1.0mm"];
    }
    return [];
  };

  const getDisabledTrackSpacings = () => {
    if (materialType === "Aluminum") {
      return ["3/3mil"];
    }
    return [];
  };

  const getDisabledLayers = () => {
    if (materialType === "Aluminum board") {
      return ["6 Layer", "8 Layer", "10 Layer", "12 Layer", "14 Layer"];
    }
    return [];
  };

  const getDisabledFinishedCopperOptions = (
    layers: string,
    material: string
  ) => {
    if (material === "FR-4") {
      if (layers === "1 Layer") {
        return ["Bare board(0 oz Cu)"];
      } else if (
        [
          "4 Layer",
          "6 Layer",
          "8 Layer",
          "10 Layer",
          "12 Layer",
          "14 Layer",
        ].includes(layers)
      ) {
        return [
          "7 oz Cu",
          "8 oz Cu",
          "9 oz Cu",
          "10 oz Cu",
          "11 oz Cu",
          "12 oz Cu",
          "13 oz Cu",
        ];
      }
    } else if (material === "Aluminum board") {
      if (layers === "1 Layer") {
        return ["Bare board(0 oz Cu)", "11 oz Cu", "12 oz Cu", "13 oz Cu"];
      } else if (layers === "4 Layer") {
        return [
          "Bare board(0 oz Cu)",
          "3 oz Cu",
          "4 oz Cu",
          "5 oz Cu",
          "6 oz Cu",
          "7 oz Cu",
          "8 oz Cu",
          "9 oz Cu",
          "10 oz Cu",
          "11 oz Cu",
          "12 oz Cu",
          "13 oz Cu",
        ];
      }
    }
    return [];
  };

  const getDisabledSilkscreenOptions = (solderMaskColor: string) => {
    switch (solderMaskColor) {
      case "Red":
        return ["Black"];
      case "Yellow":
        return ["Yellow"];
      case "Blue":
        return ["Black"];
      case "White":
        return ["White"];
      case "Black":
      case "Matt black":
        return ["Black"];
      case "Purple":
        return ["Black"];
      case "Matt green":
        return ["Black"];
      default:
        return [];
    }
  };

  useEffect(() => {
    const currentSilkscreen = form.getFieldValue("silkscreen");
    const disabledSilkscreenOptions = getDisabledSilkscreenOptions(solderMask);
    if (disabledSilkscreenOptions.includes(currentSilkscreen)) {
      form.setFieldsValue({ silkscreen: undefined });
    }
  }, [solderMask, form]);

  useEffect(() => {
    if (materialType === "Aluminum") {
      const disabledLayers = [
        "6 Layer",
        "8 Layer",
        "10 Layer",
        "12 Layer",
        "14 Layer",
      ];
      const currentLayersCount = form.getFieldValue("layersCount");
      if (disabledLayers.includes(currentLayersCount)) {
        form.setFieldsValue({ layersCount: "4 Layer" });
      }
    }
  }, [materialType, form]);

  useEffect(() => {
    if (materialType === "Aluminum") {
      const currentMinTrackSpacing = form.getFieldValue("minTrackSpacing");
      if (currentMinTrackSpacing === "3/3mil") {
        form.setFieldsValue({ minTrackSpacing: undefined });
      }
    }
  }, [materialType, form]);

  const extraTexts = [
    "Material model can be remarked below. HDI is available for 4-layer or more.",
    "For Gerber files this choice will not affect anything, PCB will be made using the parameters of the files.",
    "Requirement of Min Track/Spacing: ≥ 3/3mil for sample orders, or ≥ 3.5/3.5mil for bulk orders.",
    "Requirement of Min Track/Spacing: ≥ 3/3mil for sample orders, or ≥ 3.5/3.5mil for bulk orders.",
  ];

  function getRequirementMessage(value: string) {
    const sub = [
      { value: "Bare boaed(0 oz Cu)", min: "3/3mil", max: "3.5/3.5mil" },
      { value: "1 oz Cu", min: "3/3mil", max: "3.5/3.5mil" },
      { value: "2 oz Cu", min: "6.5/6.5mil", max: "7/7mil" },
      { value: "3 oz Cu", min: "10/10mil", max: "12/12mil" },
      { value: "4 oz Cu", min: "13/13mil", max: "14/14mil" },
      { value: "5 oz Cu", min: "18/18mil", max: "20/20mil" },
      { value: "6 oz Cu", min: "18/18mil", max: "20/20mil" },
      { value: "7 oz Cu", min: "23/23mil", max: "25/25mil" },
      { value: "8 oz Cu", min: "24/24mil", max: "25/25mil" },
      { value: "9 oz Cu", min: "24/24mil", max: "25/25mil" },
      { value: "10 oz Cu", min: "31.5/31.5mil", max: "31.5/31.5mil" },
      { value: "11 oz Cu", min: "31.5/31.5mil", max: "31.5/31.5mil" },
      { value: "12 oz Cu", min: "31.5/31.5mil", max: "31.5/31.5mil" },
    ];

    const result = sub.find((item) => item.value === value);

    if (result) {
      return `Requirement of Min Track/Spacing: ≥ ${result.min} for sample orders, or ≥ ${result.max} for bulk orders.`;
    } else {
      return "Requirement of Min Track/Spacing: ≥ 3/3mil for sample orders, or ≥ 3.5/3.5mil for bulk orders.";
    }
  }

  const handleSelectChange = (value: string) => {
    // Update form field value
    form.setFieldsValue({ finishedCopper: value });

    // Get and update the requirement message
    const message = getRequirementMessage(value);
    setRequirementMessage(message);
  };

  return (
    <div style={{ margin: 20, marginLeft: 36 }}>
      <Form.Item
        colon={false}
        name="differentDesignInPanelType"
        label="Different design in panel"
        rules={[
          { required: true, message: "Different design in panel is required" },
        ]}
        tooltip={toolTips[1]}
      >
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          {isOtherSelectedDifDesign ? (
            <Input
              size="middle"
              style={{ width: "105px" }}
              type="number"
              defaultValue={7}
              min={7}
            />
          ) : (
            <RadioCustom
              options={differentDesignPanelOptions}
              disabledValues={[]}
              groupDefaultValue={1}
            />
          )}
          <Checkbox
            style={{ marginLeft: 10 }}
            checked={isOtherSelectedDifDesign}
            onChange={handleOtherChangeDifDesign}
          >
            Other
          </Checkbox>
        </div>
      </Form.Item>

      <Form.Item
        colon={false}
        name="size"
        label="Size (single)"
        rules={[{ required: true, message: "Size is required" }]}
        tooltip={toolTips[2]}
      >
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <Space.Compact size="large">
            <Form.Item
              name="width"
              noStyle
              rules={[{ required: true, message: "Width is required" }]}
            >
              <Input
                addonAfter="X"
                placeholder="Width"
                type="number"
                style={{ width: 120 }}
              />
            </Form.Item>
            <Form.Item
              name="length"
              noStyle
              rules={[{ required: true, message: "Length is required" }]}
            >
              <Input
                placeholder="Length"
                type="number"
                style={{ width: 120 }}
              />
            </Form.Item>

            <Form.Item name="type" noStyle>
              <Select style={{ width: 70 }}>
                <Select.Option value="mm">mm</Select.Option>
                <Select.Option value="inch">inch</Select.Option>
              </Select>
            </Form.Item>
          </Space.Compact>
          <img
            alt={"sizeImg"}
            width={150}
            height={150}
            src={sizeImg}
            style={{ marginLeft: 10 }}
          />
        </div>
      </Form.Item>

      <Form.Item
        colon={false}
        name="quantityNumber"
        label="Quantity (single)"
        rules={[{ required: true, message: "Quantity is required" }]}
        tooltip={toolTips[3]}
        style={{ marginTop: 8, marginBottom: 10 }}
      >
        {isOtherSelected ? (
          <Input
            size="large"
            style={{ width: 200 }}
            placeholder="Enter quantity"
            type="number"
          />
        ) : (
          <Select
            size="large"
            showSearch
            style={{ width: 200 }}
            placeholder="Search to Select"
            optionFilterProp="label"
            filterSort={(optionA, optionB) =>
              Number(optionA.value) - Number(optionB.value)
            }
            onChange={(value) => {
              form.setFieldsValue({ quantityNumber: value });
            }}
            suffixIcon={"PCS"}
            options={quantityOptions}
          />
        )}
        <Checkbox
          style={{ marginLeft: 10 }}
          checked={isOtherSelected}
          onChange={handleOtherChange}
        >
          Other
        </Checkbox>
      </Form.Item>

      <Form.Item
        colon={false}
        name="materialType"
        label="Material"
        rules={[{ required: true, message: "Material is required" }]}
        tooltip={toolTips[6]}
        extra={<span>{extraTexts[0]}</span>}
      >
        <RadioCustom options={materialTypeOptions} />
      </Form.Item>

      <Form.Item
        colon={false}
        name="FR4Tg"
        label="FR4-TG"
        rules={[{ required: true, message: "FR4-TG is required" }]}
        tooltip={toolTips[15]}
      >
        <RadioCustom options={FR4TgOptions} />
      </Form.Item>

      <Form.Item
        colon={false}
        name="layersCount"
        label="Layers"
        rules={[{ required: true, message: "Layers is required" }]}
        tooltip={toolTips[4]}
      >
        <RadioCustom
          options={layersOptions}
          disabledValues={getDisabledLayers()}
          //TODO: need to remove thisn in feautre relasee
          // onChange={(e) => {
          //   form.setFieldsValue({ layersCount: e.target.value });
          //   form.setFieldsValue({ thicknessValue: undefined });
          // }}
        />
      </Form.Item>

      <Form.Item
        colon={false}
        name="thicknessValue"
        label="Thickness"
        rules={[
          { required: true, message: "Thickness is required" },
          { validator: thicknessValidation },
        ]}
        tooltip={toolTips[7]}
      >
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          {isOtherSelectedThiknessn ? (
            <Input
              size="large"
              style={{ width: 241 }}
              placeholder=">=1.7-8mm"
              type="number"
            />
          ) : (
            <Select
              size="large"
              defaultValue={1.6}
              showSearch
              style={{ width: 241 }}
              placeholder="Search to Select"
              optionFilterProp="label"
              filterSort={(optionA, optionB) =>
                Number(optionA.value) - Number(optionB.value)
              }
              suffixIcon={"mm"}
              options={thicknessOptions.map((option) => ({
                ...option,
                disabled: layersCount
                  ? getDisabledThicknessOptions(layersCount).includes(
                      option.value
                    )
                  : false,
              }))}
              onChange={(value) => {
                form.setFieldsValue({ thicknessValue: value });
              }}
            />
          )}
          <Checkbox
            style={{ marginLeft: 16 }}
            checked={isOtherSelectedThiknessn}
            onChange={handleOtherThiknessChange}
          >
            {`>=1.7-8mm`}
          </Checkbox>
          <img
            alt={"thikness"}
            width={70}
            height={70}
            src={thicknessImg}
            style={{ marginLeft: 10 }}
          />
        </div>
      </Form.Item>

      <Form.Item
        colon={false}
        name="minTrackSpacing"
        label="Min track/spacing"
        rules={[{ required: true, message: "Min track/spacing is required" }]}
        tooltip={toolTips[8]}
      >
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <RadioCustom
            options={trackSpacingOptions}
            disabledValues={getDisabledTrackSpacings()}
            groupDefaultValue={"6/6mil"}
          />
          <img
            alt={"trackSpacing"}
            width={80}
            height={80}
            src={trackSpaceImg}
            style={{ marginTop: 16, marginLeft: 8 }}
          />
        </div>
      </Form.Item>

      <Form.Item
        colon={false}
        name="minHoleSize"
        label="Min hole size"
        rules={[{ required: true, message: "Min hole size is required" }]}
        tooltip={toolTips[9]}
      >
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <RadioCustom
            options={holeSizeOptions}
            disabledValues={getDisabledHoleSizes()}
            groupDefaultValue={"0.3mm"}
          />
          <img
            alt={"trackSpacing"}
            width={50}
            height={50}
            src={minHoleSizeImg}
            style={{ marginTop: 16, marginLeft: 8 }}
          />
        </div>
      </Form.Item>

      <Form.Item
        colon={false}
        name="solderMask"
        label="Solder mask"
        rules={[{ required: true, message: "Solder mask is required" }]}
        tooltip={toolTips[10]}
      >
        <RadioCustom options={solderMaskOptions} />
      </Form.Item>

      <Form.Item
        colon={false}
        name="silkscreen"
        label="Silkscreen"
        rules={[{ required: true, message: "Silkscreen is required" }]}
        tooltip={toolTips[11]}
      >
        <RadioCustom
          options={slikScreenOptions}
          disabledValues={getDisabledSilkscreenOptions(solderMask)}
        />
      </Form.Item>

      <Form.Item
        colon={false}
        label="Surface finish"
        name="surfaceFinish"
        rules={[{ required: true, message: "Surface finish is required" }]}
        tooltip={toolTips[12]}
      >
        <Select
          size="large"
          style={{ width: 350 }}
          placeholder="Search to Select"
          optionFilterProp="label"
          options={surfaceFinishSelectOptions}
        />
      </Form.Item>

      <Form.Item
        colon={false}
        label="Via process"
        name="viaProcess"
        rules={[{ required: true, message: "Via process is required" }]}
        tooltip={toolTips[13]}
        extra={<span>{extraTexts[1]}</span>}
      >
        <RadioCustom options={viaProcessOptions} />
      </Form.Item>

      <Form.Item
        colon={false}
        label="Finished copper"
        name="finishedCopper"
        rules={[{ required: true, message: "Finished copper is required" }]}
        tooltip={toolTips[14]}
        extra={<span>{requirementMessage}</span>}
      >
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <Select
            defaultValue={"1 oz Cu"}
            size="large"
            style={{ width: 250 }}
            placeholder="Search to Select"
            optionFilterProp="label"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            onChange={handleSelectChange}
            options={surfaceFinishOptions.map((option) => ({
              ...option,
              disabled: getDisabledFinishedCopperOptions(
                layersCount,
                materialType
              ).includes(option.value),
            }))}
          />
          <img
            alt={"trackSpacing"}
            width={92}
            height={70}
            src={finishedCopperImg}
            style={{ marginTop: 7, marginLeft: 16 }}
          />
        </div>
      </Form.Item>
    </div>
  );
};

export default PCBForm;
