// Hooks / Node modules / Styles
import DefaultLayout from "../../../layouts/DefaultLayout";

// Reducers / Actions

// Utils / Functions

// Components

// Sub-Components

// Data / Images / Icons

function Location() {
  return (
    <DefaultLayout>
      <div>Location</div>
    </DefaultLayout>
  );
}

export default Location;
