// Hooks / Node modules / Styles
import { Radio, Form } from "antd";

// Reducers / Actions

// Utils / Functions

// Components

// Sub-Components
import OptionRow from "../OptionRow";

// Data / Images / Icons

function OrderOptions() {
  return (
    <div>
      <OptionRow title="Component sourcing">
        <Form.Item
          name="componentSourcing"
          rules={[{ required: true, message: "Please pick an item!" }]}
          className="my-auto"
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value="customer">Provided by customer</Radio.Button>
            <Radio.Button value="incbotic">Provided by Incbotic</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </OptionRow>

      <OptionRow title="PCB">
        <Form.Item
          name="pcb"
          rules={[{ required: true, message: "Please pick an item!" }]}
          className="my-auto"
        >
          <Radio.Group buttonStyle="solid">
          <Radio.Button value="customer">Provided by customer</Radio.Button>
            <Radio.Button value="incbotic">Provided by Incbotic</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </OptionRow>

      <OptionRow title="Upload Firmware">
        <Form.Item
          name="uploadFirmware"
          rules={[{ required: true, message: "Please pick an item!" }]}
          className="my-auto"
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value="yes">Yes</Radio.Button>
            <Radio.Button value="no">No</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </OptionRow>
    </div>
  )
}

export default OrderOptions