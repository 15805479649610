function Description() {
  return (
    <div className="padding-x padding-y bg-gray-200 flex flex-col items-center text-center gap-8">
      <div className="text-section  text-gray-800 w-full md:w-[800px]">
      Let's turn your concepts into durable, functional, and visually appealing reality! Discover the unparalleled advantages of large-scale electronics manufacturing with Incbotic.
      </div>
    </div>
  );
}

export default Description;
