// Hooks / Node modules / Styles
import { useState } from "react";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import classNames from "classnames";

// Reducers / Actions

// Utils / Functions

// Components

// Sub-Components
import HeaderNavigationMenu from "./_com/HeaderNavigationMenu";
import HeaderNavigationHamburgerMenu from "./_com/HeaderNavigationHamburgerMenu";
import HeaderAppUser from "./_com/HeaderAppUser";

// Data / Images / Icons
import { logo } from "../../../assets";
import { GiHamburgerMenu } from "react-icons/gi";

function Header() {
  const [open, setOpen] = useState(false);

  return (
    <Layout.Header
      className={classNames({
        "flex flex-row gap-3 items-center justify-between fixed w-full z-20": true,
        "bg-gray-200": true,
      })}
    >
      <div className="inline-flex items-center gap-2 w-full">
        {/* Logo */}
        <Link to="/" className="w-[45px] h-[40px]">
          <img src={logo} alt="logo" className="w-[45px] h-[40px]" />
        </Link>

        {/* Desktop View */}
        <div className="hidden md:block w-full">
          <HeaderNavigationMenu />
        </div>
      </div>

      {/* App User */}
      <div className="inline-flex items-center gap-2">
      <HeaderAppUser />

<div
  onClick={() => setOpen(!open)}
  className="flex md:hidden w-[50px] h-[40px] text-xl border border-gray-400 rounded-md items-center justify-center hover:cursor-pointer hover:bg-gray-300 transition-primary"
>
  <GiHamburgerMenu />
</div>
      </div>

      {/* Mobile */}
      <div className="block md:hidden">
        <HeaderNavigationHamburgerMenu
          open={open}
          onClose={() => setOpen(false)}
        />
      </div>
    </Layout.Header>
  );
}

export default Header;
