// Hooks / Node modules / Styles
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

// Reducers / Actions

// Utils / Functions

// Components

// Sub-Components

// Data / Images / Icons

function RequireAuth({ allowedRoles }) {
  const location = useLocation();
  const { roles } = useAuth();

  const content = roles.some((role) => allowedRoles.includes(role)) ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />;

  return content;
}

export default RequireAuth;
