import ReactDOM from "react-dom/client";

// Css Stylings
import "./styles/index.css";
import "./styles/alignments.css";
import "./styles/text-formattings.css";
import "./styles/gradients.css";

import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);
