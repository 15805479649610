import React from "react";
import {
  Form,
  Upload,
  UploadProps,
  message,
  Button as AntButton,
  Button,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { AiOutlineUnlock } from "react-icons/ai";
import { useMutation } from "react-query";
import { uploadBomFile } from "../../../../../../../../../api/api";

const { Dragger } = Upload;

function CplUploadArea({ pcbForm }: { pcbForm: any }) {
  const uploadMutation = useMutation(uploadBomFile, {
    onSuccess: (data: any) => {
      message.success("File uploaded successfully");
      console.log("Upload CPL response:", data.uuid);
      pcbForm.setFieldsValue({ cplId: data.uuid });
    },
    onError: (error) => {
      message.error("Failed to upload file");
      console.error("Upload error:", error);
    },
  });

  const props: UploadProps = {
    beforeUpload: (file) => {
      const allowedExtensions = [".xls", ".xlsx", ".txt", ".csv"];
      const maxSize = 10 * 1024 * 1024; // 10 MB

      const fileExtension = file.name
        .toLowerCase()
        .substring(file.name.lastIndexOf("."));
      const isAllowedExtension = allowedExtensions.includes(fileExtension);

      if (!isAllowedExtension) {
        message.error(
          `${
            file.name
          } is not allowed. Allowed extensions are: ${allowedExtensions.join(
            ", "
          )}`
        );
        return Upload.LIST_IGNORE;
      }

      if (file.size > maxSize) {
        message.error(
          `${file.name} is too large. Maximum size allowed is 10MB`
        );
        return Upload.LIST_IGNORE;
      }

      return true;
    },
    customRequest: async ({ file, onSuccess, onError }) => {
      const formData = new FormData();
      formData.append("cpl", file);

      try {
        await uploadMutation.mutateAsync(formData);
        onSuccess?.(null);
      } catch (error) {
        onError?.(error as any);
      }
    },
  };

  const normFile = (e: any) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <div style={{ margin: 20 }}>
      <Form.Item name="cplId"></Form.Item>
      <Form.Item
        name="cplFile"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              const isPcbAssembly = getFieldValue("IsPcbAssembly");
              if (isPcbAssembly && (!value || value.length === 0)) {
                return Promise.reject("CPL file is required");
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Dragger {...props}>
          <div className="p-5 flex flex-col items-center justify-center">
            <AntButton
              type="primary"
              shape="round"
              size="large"
              icon={<UploadOutlined />}
            >
              Add CPL file
            </AntButton>
            <p className="ant-upload-text pt-2">
              Click or drag file to this area to upload
            </p>
            <div className="flex flex-col md:flex-row gap-3 pt-3">
              <p className="ant-upload-hint">
                {" "}
                Only accept .xls, .xlsx, .txt or .csv.
              </p>
              <p className="ant-upload-hint inline-flex gap-2 items-center justify-center">
                <div className="text-gray-700 font-semibold">
                  <AiOutlineUnlock />
                </div>
                <span>All uploads are secure and confidential</span>
              </p>
            </div>
            <Button
              style={{ marginTop: 8 }}
              type="link"
              onClick={() => {
                const cpldownloadUrl = `https://sampledataformatbucket.s3.ap-south-1.amazonaws.com/PickAndPlace_SampleFormat.csv`;
                window.open(cpldownloadUrl, "_blank");
              }}
            >
              Dowload sample CPL
            </Button>
          </div>
        </Dragger>
      </Form.Item>
    </div>
  );
}

export default CplUploadArea;
