function Description() {
  return (
    <div className="padding-x padding-y bg-gray-200 flex flex-col items-center text-center gap-8">
      <div className="text-section  text-gray-800 w-full md:w-[800px]">
        Get your PCBs assembled to industry standards with quality,
        affordability, and fastest delivery!
      </div>
    </div>
  );
}

export default Description;
