import React, { useState } from "react";
import {
  Form,
  Upload,
  UploadProps,
  message,
  Button as AntButtons,
  Image,
  Space,
  ConfigProvider,
  Alert,
} from "antd";
import {
  ArrowRightOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { AiOutlineUnlock } from "react-icons/ai";
import type { RcFile, UploadFile } from "antd/es/upload/interface";
import { useMutation } from "react-query";
import { uploadGerberFile } from "../../../../../../../../api/api";
import topImg from "./simulation_image_top.png";
import bottomImg from "./simulation_image_bottom.png";

const { Dragger } = Upload;

interface UploadResponse {
  message: string;
  response: {
    s3Link: string;
    uuid: string;
  };
}

interface UploadGerber {
  form: any;
}

const props: UploadProps = {
  beforeUpload: (file) => {
    const isZip = file.name.toLowerCase().endsWith(".zip");
    const isRar = file.name.toLowerCase().endsWith(".rar");
    const maxSize = 10 * 1024 * 1024; // 10 MB

    if (!isZip && !isRar) {
      message.error(`${file.name} is not a zip or rar file`);
      return Upload.LIST_IGNORE;
    }

    if (file.size > maxSize) {
      message.error(`${file.name} is too large. Maximum size allowed is 10MB`);
      return Upload.LIST_IGNORE;
    }

    return true;
  },
  maxCount: 1,
  listType: "picture",
};

const GerberUploadArea: React.FC<UploadGerber> = ({ form }) => {
  const [showImages, setShowImages] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [response, setResponse] = useState<string>();
  const [topImageUrl, setTopImageUrl] = useState<string | null>(null);
  const [bottomImageUrl, setBottomImageUrl] = useState<string | null>(null);
  const [isImageLoading, setIsImageLoading] = useState(false);

  const mutation = useMutation(uploadGerberFile, {
    onSuccess: async (data) => {
      message.success("File uploaded successfully to server");
      form.setFieldsValue({ gerberId: data[0].response.uuid });
      form.setFieldsValue({ length: data[0].response.height });
      form.setFieldsValue({ size: data[0].response.width });
      form.setFieldsValue({ width: data[0].response.width });
      setResponse(data[0].response.s3Link);
      setShowImages(true);
      // setIsImageLoading(true);

      // try {
      //   // Upload to PCBWay
      //   const pcbwayFormData = new FormData();
      //   pcbwayFormData.append("name", fileList[0].name);
      //   pcbwayFormData.append("uptype", "gerberfile");
      //   pcbwayFormData.append("file", fileList[0].originFileObj as Blob);

      //   // const pcbwayResponse = await uploadGerberToPCBWay(pcbwayFormData);
      //   // message.success("File uploaded successfully to PCBWay");
      //   // console.log("PCBWay upload response:", pcbwayResponse);

      //   // // Convert Gerber to PNG
      //   // if (pcbwayResponse.data && pcbwayResponse.data.url) {
      //   //   const pngResponse = await convertGerberToPng(pcbwayResponse.data.url);
      //   //   console.log("Gerber to PNG response:", pngResponse);

      //   //   if (pngResponse.result && pngResponse.msg) {
      //   //     const [topUrl, bottomUrl] = pngResponse.msg.split(",");
      //   //     setTopImageUrl(topUrl);
      //   //     setBottomImageUrl(bottomUrl);
      //   //   } else {
      //   //     message.warning(
      //   //       "Unexpected response format from Gerber to PNG conversion"
      //   //     );
      //   //   }
      //   // }
      // } catch (error) {
      //   message.error("Failed to process the Gerber file");
      //   console.error("Error:", error);
      // } finally {
      //   setIsImageLoading(false); // Set loading to false when done
      // }
    },
    onError: (error) => {
      form.setFieldsValue({ gerberId: undefined });
      message.error("Upload failed");
      console.error("Upload error:", error);
    },
  });

  const handleUpload = (file: RcFile) => {
    const formData = new FormData();
    formData.append("gerber", file);
    setFileList([file as UploadFile]);
    mutation.mutate(formData);
  };

  const normFile = (e: any) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleReset = () => {
    setShowImages(false);
    setFileList([]);
    setTopImageUrl(null);
    setBottomImageUrl(null);
  };

  const handleViewGerber = (urlLink: string) => {
    const url = `https://incbotic.com/gerberviewer/?boardUrl=${urlLink}`;
    window.open(url, "_blank");
  };

  return (
    <div style={{ margin: 20 }}>
      <Alert
        message="This is beta version of the fabrication and assembly feature. If you experience any technical issues or encounter any blockers, please reach out to the Incbotic team for assistance."
        banner
        style={{ height: "60px", maxWidth: "1020px" }}
      />
      <Form.Item name="gerberId"></Form.Item>
      <Form.Item
        name="gerberFile"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: "Gerber file is required" }]}
      >
        <Dragger
          {...props}
          fileList={fileList}
          onChange={({ fileList }) => setFileList(fileList)}
          customRequest={({ file, onSuccess, onError }) => {
            if (file instanceof Blob) {
              handleUpload(file as RcFile);
              onSuccess && onSuccess("ok");
            } else {
              onError && onError(new Error("File is not a valid RcFile"));
            }
          }}
          disabled={showImages}
        >
          <div className="p-5 flex flex-col items-center justify-center">
            {showImages && (
              <>
                {
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "50px",
                        marginTop: "20px",
                      }}
                    >
                      {/* <Image width={230} src={topImageUrl || topImg} />
                      <Image width={230} src={bottomImageUrl || bottomImg} /> */}
                    </div>
                  </>
                }

                <Space style={{ marginTop: 45 }}>
                  <AntButtons
                    icon={<UploadOutlined />}
                    size="large"
                    onClick={handleReset}
                    color="#fc6076"
                  />
                  <ConfigProvider
                    theme={{
                      components: {
                        Button: {
                          colorFillSecondary: `linear-gradient(90deg, #fc6076, #ff9a44, #ef9d43, #e75516)`,
                          colorPrimary:
                            "linear-gradient(135deg, #6253E1, #04BEFE) !important",
                          primaryShadow: "none",
                          defaultBg:
                            "linear-gradient(90deg, #aea4e3, #d3ffe8) !important",
                          defaultShadow: "none",
                          defaultColor: "#fff !important",
                          lineWidth: 0,
                        },
                      },
                    }}
                  >
                    <AntButtons
                      icon={<ArrowRightOutlined />}
                      size="large"
                      type="primary"
                      iconPosition="end"
                      onClick={() => {
                        if (response) {
                          handleViewGerber(response);
                        }
                      }}
                    >
                      View Gerber
                    </AntButtons>
                  </ConfigProvider>
                </Space>
              </>
            )}

            {!showImages && (
              <>
                <AntButtons
                  type="primary"
                  shape="round"
                  size="large"
                  loading={mutation.isLoading}
                  icon={<UploadOutlined />}
                >
                  Add Gerber file
                </AntButtons>
                <p className="ant-upload-text pt-2">
                  Click or drag file to this area to upload
                </p>
                <div className="flex flex-col md:flex-row gap-3 pt-3">
                  <p className="ant-upload-hint">Only accept .zip or .rar. </p>
                  <p className="ant-upload-hint inline-flex gap-2 items-center justify-center">
                    <div className="text-gray-700 font-semibold">
                      <AiOutlineUnlock />
                    </div>
                    <span>All uploads are secure and confidential</span>
                  </p>
                </div>
              </>
            )}

            <AntButtons
              style={{ marginTop: 8 }}
              type="link"
              onClick={() =>
                window.open("https://incbotic.com/terms-conditions", "_blank")
              }
            >
              Instructions For Ordering
            </AntButtons>
          </div>
        </Dragger>
      </Form.Item>
    </div>
  );
};

export default GerberUploadArea;
