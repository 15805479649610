// Hooks / Node modules / Styles
import DefaultLayout from "../../../../layouts/DefaultLayout";

// Reducers / Actions

// Utils / Functions

// Components

// Sub-Components
import Hero from "./_com/Hero";
import Description from "./_com/Description";
import Services from "./_com/Services";
import Steps from "./_com/Steps";
import PcbAssemblyForm from "./_com/form/PcbAssemblyForm";
import {AuthContext, IAuthContext} from "../../../../auth/AuthContextProvider";
import {SignIn} from "../../../../auth/AuthComponents";
import React from "react";

// Data / Images / Icons

function PcbAssmebly() {
  return (
    <DefaultLayout>
      <div>
        <Hero />
        <Description />
        <Services />
        <Steps />
        <AuthContext.Consumer>{(auth:IAuthContext)=>auth.loggedIn?<PcbAssemblyForm />:<SignIn/>}</AuthContext.Consumer>
      </div>
    </DefaultLayout>
  );
}

export default PcbAssmebly;
