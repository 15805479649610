import { StepList } from "../../../../../components";

import {
  GiLightBulb,
  GiAnvil,
  GiWrench,
  GiGears,
  GiShield,
  GiEarthAfricaEurope,
} from "react-icons/gi";
import { BiInjection } from "react-icons/bi";

interface IStep {
  icon?: any;
  title?: string;
  description?: string;
}

const DATA_STEPS: IStep[] = [
  {
    icon: <GiLightBulb />,
    title: "Innovative Design",
    description: "Engineered from your vision",
  },
  {
    icon: <BiInjection />,
    title: "Injection molding",
    description: "Top-tier precision and finish for your products",
  },
  {
    icon: <GiAnvil />,
    title: "Metal forming",
    description: "Enclosures that are robust and built to last",
  },
  {
    icon: <GiWrench />,
    title: "End-to-End Solutions",
    description: "From initial concept to final product",
  },
  {
    icon: <GiGears />,
    title: "Scalability",
    description: "Equipped to meet your growing demands",
  },
  {
    icon: <GiShield />,
    title: "Quality Assurance",
    description: "Quality is not an option; it's our commitment",
  },
  {
    icon: <GiEarthAfricaEurope />,
    title: "Global Reach",
    description: "Logistics services to ensure on-time delivery",
  },
];

function Steps() {
  return (
    <div>
      <StepList title="Steps" data={DATA_STEPS} />
    </div>
  );
}

export default Steps;
