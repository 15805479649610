function Description() {
  return (
    <div className="padding-x padding-y bg-gray-200 flex flex-col items-center text-center gap-8">
      <div className="text-section  text-gray-800 w-full md:w-[800px]">
        Are you ready to revolutionize your business and embrace the future of
        connectivity? Look no further! Our end-to-end IoT solutions are designed
        to transform your operations, enhance efficiency, and unlock new
        possibilities.
      </div>
    </div>
  );
}

export default Description;
