// Hooks / Node modules / Styles
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

// Reducers / Actions

// Utils / Functions

// Components

// Sub-Components

// Data / Images / Icons
// import DATA_HEADER_NAVIGATIONS from "../../../../data/DATA_HEADER_NAVIGATIONS";
import {
  AppstoreOutlined,
  DesktopOutlined,
  // DeploymentUnitOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

function HeaderNavigationMenu() {
  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");

  return (
    // <Menu
    //   onClick={onClick}
    //   selectedKeys={[current]}
    //   mode="horizontal"
    //   items={DATA_HEADER_NAVIGATIONS}
    //   className="w-full bg-gray-200 font-semibold text-md text-gray-600"
    // />
    <Menu
      mode="horizontal"
      selectedKeys={[pathnameSegments[1]]}
      className="w-full bg-gray-200 font-semibold text-md text-gray-600"
    >
      <Menu.SubMenu key="service" icon={<AppstoreOutlined />} title="Service">
        <div className="flex flex-row gap-3 p-3">
          <div>
            <div className="font-semibold text-gray-400 py-4">Hardware</div>
            <Menu.Item key="service:hardware:custom_design">
              <Link to="/service/hardware/custom-solutions">
                Custom Solutions
              </Link>
            </Menu.Item>
            <Menu.Item key="service:hardware:pcb_design">
              <Link to="/service/hardware/pcb-design">PCB Design</Link>
            </Menu.Item>
            <Menu.Item key="service:hardware:pcb_fabrication">
              <Link to="/service/hardware/pcb-fabrication">
                PCB Fabrication
              </Link>
            </Menu.Item>
            <Menu.Item key="service:hardware:pcb_assembly">
              <Link to="/service/hardware/pcb-assembly">PCB Assembly</Link>
            </Menu.Item>
            <Menu.Item key="service:hardware:bom_service">
              <Link to="/service/hardware/bom-service">BOM Service</Link>
            </Menu.Item>
          </div>
          <div>
            <div className="font-semibold text-gray-400 py-4">Software</div>
            <Menu.Item key="service:software:web_solutions">
              <Link to="/service/software/web-solutions">Web Solutions</Link>
            </Menu.Item>
            {/* <Menu.Item key="service:software:cloud_solutions">
              <Link to="/service/software/cloud-solutions">
                Cloud Solutions
              </Link>
            </Menu.Item> */}
          </div>
          <div>
            <div className="font-semibold text-gray-400 py-4">IoT</div>
            <Menu.Item key="service:software:iot_solutions">
              <Link to="/service/iot/iot-solutions">IoT Solutions</Link>
            </Menu.Item>
            <Menu.Item key="service:software:large_scale_manufacturing">
              <Link to="/service/iot/large-scale-manufacturing">
                Large Scale Manufacturing
              </Link>
            </Menu.Item>
          </div>
        </div>
      </Menu.SubMenu>
      <Menu.Item key="careers" icon={<DesktopOutlined />}>
        <Link to="/careers">Careers</Link>
      </Menu.Item>
      <Menu.SubMenu
        key="about-us"
        icon={<EnvironmentOutlined />}
        title="About Us"
      >
        <Menu.Item key="about_us:vision_mission_and_value">
          <Link to="/about-us/vision-mission-and-value">
            Vision, Mission and Value
          </Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key="contact-us" icon={<PhoneOutlined />}>
        <Link to="/contact-us">Contact Us</Link>
      </Menu.Item>
    </Menu>
  );
}

export default HeaderNavigationMenu;
