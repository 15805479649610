// Hooks / Node modules / Styles

// Reducers / Actions

// Utils / Functions

// Components

// Sub-Components

// Data / Images / Icons
import { logo_metalic_1, logo_metalic_1_mobile } from "../../../assets"

function Hero() {
  return (
    <div className="h-fit">
      <img src={logo_metalic_1} alt="logo_metalic_1" className="object-cover w-full h-full hidden md:block" />
      <img src={logo_metalic_1_mobile} alt="logo_metalic_1_mobile" className="object-cover w-full h-full block md:hidden" />
    </div>
  )
}

export default Hero