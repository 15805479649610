// Hooks / Node modules / Styles

// Reducers / Actions

// Utils / Functions

// Components
import DefaultLayout from "../../../../layouts/DefaultLayout";
import Hero from "./_com/Hero";
import Description from "./_com/Description";
import Steps from "./_com/Steps";
import ContactRedirect from "./_com/ContactRedirect";
import Experience from "./_com/Experience";

// Sub-Components

// Data / Images / Icons

function WebSolutions() {
  return (
    <DefaultLayout>
      <div>
        <Hero />
        <Description />
        <Steps />
        {/* <Experience /> */}
        <ContactRedirect />
      </div>
    </DefaultLayout>
  );
}

export default WebSolutions;
