import React, { createContext, useEffect, useState } from "react";
import { Auth } from "./Auth";
import { userInfo } from "../api/api";
import { unstable_batchedUpdates } from "react-dom";

export interface IAuthContext {
  loggedIn: boolean;
  user?: IUser;
}

export interface IUser {
  sub: string;
  user: string;
  email: string;
  phone_number: string;
}

const AuthContext = createContext<IAuthContext>({ loggedIn: false });

const AuthContextProvider: React.FC<any> = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState<boolean>(Auth.isSignedIn());
  const [user, setUser] = useState<IUser>();

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (Auth.isSignedIn()) {
        try {
          const { data } = await userInfo();
          unstable_batchedUpdates(() => {
            setUser(data);
            setLoggedIn(true);
          });
        } catch (error) {
          console.error("Error fetching user info:", error);
          Auth.signOut();
        }
      }
    };

    fetchUserInfo();

    Auth.subscribe(async (state: boolean) => {
      if (state) {
        fetchUserInfo();
      } else {
        unstable_batchedUpdates(() => {
          setUser(undefined);
          setLoggedIn(false);
        });
      }
    });
  }, []);

  return (
    <AuthContext.Provider value={{ loggedIn, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
export { AuthContext };
