import DefaultLayout from "../../../../layouts/DefaultLayout";
import Hero from "./_com/Hero";
import PcbFabricationForm from "./_com/form/PcbFabricationForm";
import {
  AuthContext,
  IAuthContext,
} from "../../../../auth/AuthContextProvider";
import { SignIn } from "../../../../auth/AuthComponents";

function PcbFabrication() {
  return (
    <DefaultLayout>
      <div>
        <Hero />
        <AuthContext.Consumer>
          {(auth: IAuthContext) =>
            auth.loggedIn ? <PcbFabricationForm /> : <SignIn />
          }
        </AuthContext.Consumer>
      </div>
    </DefaultLayout>
  );
}

export default PcbFabrication;
