import HorizontalTimelineCard from "./_com/HorizontalTimelineCard";

interface IStep {
  icon?: any;
  image?: any;
  title?: string;
  description?: string;
}

type HorizontalTimelineProps = {
  data?: IStep[];
};

function HorizontalTimeline({ data }: HorizontalTimelineProps) {
  return (
    <div className="relative flex flex-row md:block">
      {/* Horizontal Line */}
      <div className="hidden md:grid md:grid-cols-5">
        <div className="col-span-4 bg-gray-500/20 w-full h-[5px] rounded-full" />
      </div>

      {/* Vertical Line */}
      <div className="absolute left-[20px] grid md:hidden md:grid-rows-5">
        <div className="row-span-4 bg-gray-500/20 w-[5px] h-[540px] rounded-full" />
      </div>

      {/* Cards */}
      <div className="absolute md:top-[-20px] grid grid-rows-5 md:grid-cols-5">
        {data?.map((item, index) => {
          return (
            <HorizontalTimelineCard
              key={index}
              icon={item?.icon}
              title={item?.title}
              description={item?.description}
            />
          );
        })}
      </div>
    </div>
  );
}

export default HorizontalTimeline;
