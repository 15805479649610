// Hooks / Node modules / Styles
import { useState } from "react";
import { Form } from "antd";
import * as yup from "yup";
import { Button } from "../../../../../../components";
import { uploadPCBDesign } from "../../../../../../api/api";
import UploadForm from "../../../../../../utils/helpers/UploadForm";

// Reducers / Actions

// Utils / Functions

// Components

// Sub-Components
import GerberUploadArea from "./GerberUploadArea";

// Data / Images / Icons
const INITIAL_DATA = {};

let schema = yup.object().shape({
  gerber: yup
    .mixed()
    .test(
      "isGerber",
      "Invalid file format. Please upload a Gerber file.",
      (value: any) => {
        if (!value) return true; // Allow an empty value
        return (
          value[0]?.type === "image/jpeg" ||
          value[0]?.type === "image/jpg" ||
          value[0]?.type === "image/JPEG" ||
          value[0]?.type === "image/JPG" ||
          value[0]?.type === "application/sch"
        );
      }
    )
    .test(
      "fileSize",
      "File is too large. Maximum size is 10MB.",
      (value: any) => {
        if (!value) return true; // Allow an empty value
        return value[0].size <= 10 * 1024 * 1024; // 10MB
      }
    )
    .required("File is required"),
});

const yupSync = {
  async validator({ field }: any, value: any) {
    await schema.validateSyncAt(field, { [field]: value });
  },
};

function PcbDesignForm() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async (values: any) => {
    const file: any = values.gerber[0];
    if (file) {
      const formData = new FormData();
      formData.append("size", (file.size / 1e6).toString());
      formData.append("file_name", file.name);
      formData.append("file", file.originFileObj);
      await uploadPCBDesign(formData);
      return true;
    }
    return false;
  };

  return (
    <div className="bg-bg_circuit bg-opacity-10">
      <UploadForm
        form={form}
        initialValues={INITIAL_DATA}
        name="pcbDesignForm"
        autoComplete="off"
        layout="vertical"
        onSubmit={onSubmit}
        setIsLoading={setIsLoading}
      >
        <div className="padding-x padding-y">
          <div className="text-[35px] font-bold text-gray-600 text-center pb-5 md:pb-[40px]">
            Upload File
          </div>
          <div className="pb-5">
            <GerberUploadArea rules={[yupSync]} />
          </div>
          <Form.Item>
            <Button
              text="Submit"
              htmlType="submit"
              customStyles="w-full md:w-[200px] text-lg font-semibold py-7 rounded-lg mx-auto"
              isLoading={isLoading}
            />
          </Form.Item>
        </div>
      </UploadForm>
    </div>
  );
}

export default PcbDesignForm;
