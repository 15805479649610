import {
  AiOutlineCloudUpload,
  AiOutlineGateway,
  AiOutlineWifi,
  AiOutlineCloud,
  AiOutlineAppstore,
  AiOutlineBarChart,
  AiOutlineDesktop,
  AiOutlineLock,
  AiOutlineCode,
} from 'react-icons/ai';

import { StepList } from "../../../../../components";

interface IStep {
  icon?: any;
  title?: string;
  description?: string;
}

const DATA_STEPS: IStep[] = [
  {
    icon: <AiOutlineCloudUpload />,
    title: "Devices",
  },
  {
    icon: <AiOutlineWifi />,
    title: "Sensors and Actuators",
  },
  {
    icon: <AiOutlineGateway />,
    title: "Gateways",
  },
  {
    icon: <AiOutlineCloud />,
    title: "No-code Cloud Platform",
  },
  {
    icon: <AiOutlineAppstore />,
    title: "Applications",
  },
  {
    icon: <AiOutlineBarChart />,
    title: "Analytics",
  },
  {
    icon: <AiOutlineDesktop />,
    title: "User Interface",
  },
  {
    icon: <AiOutlineLock />,
    title: "Security",
  },
  {
    icon: <AiOutlineCode />,
    title: "Firmware and Software",
  },
];

function Offerings() {
  return (
    <div>
      <StepList title="We Offer" data={DATA_STEPS} customStyles='bg-gray-100' />
    </div>
  );
}

export default Offerings;
