// Hooks / Node modules / Styles
import DefaultLayout from "../../../../layouts/DefaultLayout";

// Reducers / Actions

// Utils / Functions

// Components

// Sub-Components
import Hero from "./_com/Hero";
import Description from "./_com/Description";
import WhyUs from "./_com/WhyUs";
import Steps from "./_com/Steps";
import Experience from "./_com/Experience";
import ContactRedirect from "./_com/ContactRedirect";

// Data / Images / Icons

function CustomSolutions() {
  return (
    <DefaultLayout>
      <div>
        <Hero />
        <Description/>
        <WhyUs />
        <Steps />
        <Experience />
        <ContactRedirect />
      </div>
    </DefaultLayout>
  );
}

export default CustomSolutions;
