// constants.js

export const sizeImg =
  "https://www.pcbway.com/img/images/newpcbway/xy-sizepanel.png";
export const thicknessImg =
  "https://www.pcbway.com/img/images/newpcbway/thickness.png";
export const trackSpaceImg =
  "https://www.pcbway.com/img/images/newpcbway/track.png";
export const minHoleSizeImg =
  "https://www.pcbway.com/img/images/newpcbway/hole.png";
export const finishedCopperImg =
  "https://www.pcbway.com/img/images/newpcbway/copper.png";

export const toolTips = [
  "PCBs will be shipped in single pieces or in panel. For bulk assembly orders (quantity greater than 30), we recommend shipping in panel, which will improve the assembly",
  "It means there are different designs in your files separated by v-cuts, stamp holes, milling slots or others. We will charge additional panel cost if there are different kinds of PCB designs in one Gerber file.",
  "The board size refers to the length and width of the board ordered by the customer. If it is a circular board, the length and width are the diameter of the circle; If it is a board with irregular shape, the length and width are measured according to the largest shape range.",
  "Please choose the number of single pieces you need. For standard PCB, the starting quantity is 5 pieces.",
  "The number of copper layers in the board. Incbotic can currently produce up to 60 layers, if you need more than 14 layers, please contact the Incbotic team .",
  "Bad PCBs may exist in the panel, the quotation will increase by about 30% if X-out Allowance is not accepted.",
  "FR4 is the most common dielectric material used in PCB fabrication. Aluminum boards have better heat dissipation than standard FR-4. Copper base boards have better thermal conductivity than aluminum. For other materials, contact your sales representative.",
  "For ordinary materials, the board thickness refers to the thickness of the PCB after production is complete, with an error of about 10% (+/-10% for T>=1mm or +/-0.1mm for T<1mm). This error is mainly due to solder mask and copper sinking. ",
  "Minimum width of the copper trace and minimum distance between two traces. Incbotic engineers will double-check the Min Track/Spacing.",
  "Minimum diameter of holes. Incbotic engineers will double-check the min hole size.",
  "Solder mask color refers to the color of the PCB surface. Currently, Incbotic provides 9 conventional solder mask colors. If you need pink, gray, orange, or transparent, please go to the Advanced PCB page to choose.",
  "The silkscreen is a layer of ink trace used to identify the PCB components, marks, logos, symbols, and so on. The color is usually white. But when the solder mask ink is white, the silkscreen will be black.",
  "Surface finish is an important part of PCB production. It can protect the copper from oxidation, which would render the PCB unusable. In addition, it provides a smooth surface for the components to be soldered.",
  "For gerber files, we will process according to the files, and this option is invalid. For .PCB or .PCBDOC files, we will process with the parameters you select.",
  "The weight in ounces of copper in one square foot of PCB, this option will determine the thickness of copper on the outer layers. Incbotic provides the thickness of copper on outer layers of 1-13oz.PTH holes or vias that are cutted through to create a partial or half hole to form an opening into the side of the hole barrel. Generally they are used for mounting a PCB to another one.",
  "TG means Glass Transition Temperature. Ordinary TG is 130 ℃ or more, medium TG is about greater than 150 ℃, and advanced TG is generally greater than 170 ℃. The higher the TG, the better the temperature resistance of the material. The default material of Incbotic has a TG of 150-160℃, which is higher that most companies.",
];

export const layersOptions = [
  "1 Layer",
  "2 Layer",
  "4 Layer",
  "6 Layer",
  "8 Layer",
  "10 Layer",
  "12 Layer",
  "14 Layer",
].map((layer) => ({ label: layer, value: layer }));

export const differentDesignPanelOptions = Array.from(
  { length: 6 },
  (_, i) => ({
    label: i + 1,
    value: i + 1,
  })
);

export const trackSpacingOptions = [
  "3/3mil",
  "4/4mil",
  "5/5mil",
  "6/6mil",
  "8/8mil",
].map((spacing) => ({ label: spacing, value: spacing }));

export const holeSizeOptions = [
  "0.15mm",
  "0.2mm",
  "0.25mm",
  "0.3mm",
  "0.8mm",
  "1.0mm",
  "No Drill",
].map((spacing) => ({ label: spacing, value: spacing }));

export const viaProcessOptions = [
  "Tenting vias",
  "Vias not covered",
  "Plugged vias",
].map((spacing) => ({ label: spacing, value: spacing }));

export const surfaceFinishOptions = [
  "Bare boaed(0 oz Cu)",
  "1 oz Cu",
  "2 oz Cu",
  "3 oz Cu",
  "4 oz Cu",
  "5 oz Cu",
  "6 oz Cu",
  "7 oz Cu",
  "8 oz Cu",
  "9 oz Cu",
  "10 oz Cu",
  "11 oz Cu",
  "12 oz Cu",
  "13 oz Cu",
].map((spacing) => ({
  label: spacing,
  value: spacing,
}));
export const surfaceFinishSelectOptions = [
  "HASL with lead",
  "HASL lead free",
  "Immersion gold",
  "OSP",
  "Hard Gold",
  "None",
].map((spacing) => ({
  label: spacing,
  value: spacing,
}));

export const FR4TgOptions = ["TG130", "TG150", "TG170"].map((spacing) => ({
  label: spacing,
  value: spacing,
}));

export const thicknessOptions = [
  0.2, 0.3, 0.4, 0.6, 0.8, 1.0, 1.2, 1.6, 2.0, 2.4, 2.6, 2.8, 3.0, 3.2,
].map((thickness) => ({ label: thickness, value: thickness }));

export const materialTypeOptions = [
  {
    label: "FR-4",
    value: "FR-4",
    image: "https://www.pcbway.com/img/images/newpcbway/fr4_metarial.jpg",
  },
  {
    label: "Aluminum",
    value: "Aluminum board",
    image: "https://www.pcbway.com/img/images/newpcbway/Aluminum-Board.jpg",
  },
];

export const solderMaskOptions = [
  {
    label: "Green",
    value: "Green",
    colored: "#017b07",
  },
  {
    label: "Red",
    value: "Red",
    colored: "#bb1407",
  },
  {
    label: "Yellow",
    value: "Yellow",
    colored: "#ffd200",
  },
  {
    label: "Blue",
    value: "Blue",
    colored: "#0c4ad9",
  },
  {
    label: "White",
    value: "White",
    colored: "#ffff",
  },
  {
    label: "Black",
    value: "Black",
    colored: "#0f0f0f",
  },
  {
    label: "Purple",
    value: "Purple",
    colored: "#a20495",
  },
  {
    label: "Matte Black",
    value: "Matt black",
    colored: "#0f0f0f",
  },
  {
    label: "Matte Green",
    value: "Matt green",
    colored: "#218626",
  },
  {
    label: "None",
    value: "None",
    colored: "#FAF9F6",
  },
];

export const slikScreenOptions = [
  {
    label: "White",
    value: "White",
    colored: "#ffff",
  },
  {
    label: "Black",
    value: "Black",
    colored: "#0f0f0f",
  },
  {
    label: "None",
    value: "None",
    colored: "#FAF9F6",
  },
];

const increment = 5;
const limit = 10000;
export const quantityOptions = Array.from(
  { length: limit / increment },
  (_, index) => {
    const value = (index + 1) * increment;
    return { value, label: value.toString() };
  }
);
