import { Input, Form, Collapse } from "antd";



const MainContent = () => {
  return <Form.Item
      name="specialRequirements"
      label="Special Requirements"
    >
    <Input.TextArea rows={4} placeholder="Enter text here"/>
  </Form.Item>
};

// since we can collapse the Collapse by default, no extra step to hide this test field is not necessary
function AdvancedOptions() {
  return (
    <Collapse
      items={[
        { key: "1", label: "Advanced Options", children: <MainContent /> },
      ]}
      style={{margin: 20}}
    />
  );
}

export default AdvancedOptions;
