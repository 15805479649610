function Description() {
  return (
    <div className="padding-x padding-y bg-gray-200 flex flex-col items-center text-center gap-8">
      <div className="text-section  text-gray-800 w-full md:w-[800px]">
        Are you an innovator with a brilliant
        product concept itching to bring it to life? At Incbotic, we specialize
        in transforming your ideas into tangible, functional realities.
      </div>
      <div className="text-section  text-gray-800 w-full md:w-[800px]">
        End-to-end delivery of projects managed by our in-house team of experts
        using professional design and manufacturing tools that reduce time and
        risk of delivery, ensuring your vision becomes a successful and
        marketable product.
      </div>
    </div>
  );
}

export default Description;
