import React from "react";
import { Button as AntdButton, Typography } from "antd";
import { authCfg } from "../api/api";
import { Auth } from "../auth/Auth";

const signInRedirect = async () => {
  const cfg = await authCfg();
  if (cfg) {
    const urlParams = new URLSearchParams();
    urlParams.append("response_type", "code");
    urlParams.append("client_id", cfg.client_id);
    urlParams.append("redirect_uri", cfg.redirect_uri);
    const url = `${cfg.server_endpoint}/login?${urlParams.toString()}`;
    window.location.replace(url);
  }
};

export const SignIn: React.FunctionComponent = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", margin: 40 }}>
      <Typography.Title level={4}>
        Please <a onClick={signInRedirect}>Sign in</a> to Continue
      </Typography.Title>
    </div>
  );
};

export const SignInMenuComp: React.FC = () => {
  return <Typography.Text onClick={signInRedirect}>Sign In</Typography.Text>;
};

export const SignOutMenuComp: React.FC = () => {
  const handleSignOut = () => {
    Auth.signOut();
    window.location.href = "/"; // Redirect to home or login page
  };

  return <AntdButton onClick={handleSignOut}>Sign Out</AntdButton>;
};
