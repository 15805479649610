import classNames from "classnames";

type ExampleCardProps = {
  image?: any;
  index: number;
  title?: string;
  description?: string;
};

function ExampleCard({ image, index, title, description }: ExampleCardProps) {
  return (
    <div
      className={classNames({
        "padding-x padding-y flex flex-col gap-5 md:gap-10 bg-opacity-10": true,
        "md:flex-row bg-gray-400": index % 2 === 0,
        "md:flex-row-reverse": index % 2 === 1,
      })}
    >
      <img src={image} alt={title} className="w-[600px]" />
      <div>
        <div
          className={classNames({
            "text-[35px] font-bold text-gray-800 text-center pb-5 md:pb-[10px]":
              true,
            "md:text-left": index % 2 === 0,
            "md:text-right": index % 2 === 1,
          })}
        >
          {title}
        </div>
        <div
          className={classNames({
            "text-section  text-gray-800 w-full text-center": true,
            "md:text-left": index % 2 === 0,
            "md:text-right": index % 2 === 1,
          })}
        >
          {description}
        </div>
      </div>
    </div>
  );
}

export default ExampleCard;
