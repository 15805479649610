import {
  AiOutlineCloudUpload,
  AiOutlineCode,
  AiOutlineShop,
  AiOutlineSearch,
  AiOutlineMobile,
  AiOutlineSetting,
  AiOutlineSafetyCertificate,
  AiOutlineBarChart,
} from 'react-icons/ai';

import { StepList } from "../../../../../components";

interface IStep {
  icon: any;
  title: string;
  description: string;
}

const DATA_STEPS: IStep[] = [
  {
    icon: <AiOutlineCode />,
    title: "Website Design & Development",
    description: "Craft visually stunning and user-friendly websites",
  },
  {
    icon: <AiOutlineCode />,
    title: "Content Management Systems (CMS)",
    description: "Edit content effortlessly with no coding required",
  },
  {
    icon: <AiOutlineShop />,
    title: "E-commerce Solutions",
    description: "Set up an online store with an integrated payment gateway",
  },
  {
    icon: <AiOutlineSearch />,
    title: "SEO & Digital Marketing",
    description: "Drive more organic traffic to your site",
  },
  {
    icon: <AiOutlineMobile />,
    title: "Mobile-Friendly Design",
    description:
      "Your website will look and function flawlessly on all devices",
  },
  {
    icon: <AiOutlineSetting />,
    title: "Web Hosting & Maintenance",
    description: "Leave the technical hassles to us!",
  },
  {
    icon: <AiOutlineSafetyCertificate />,
    title: "Web Security",
    description: "Stay protected with built-in security features and logins",
  },
  {
    icon: <AiOutlineBarChart />,
    title: "Analytics & Reporting",
    description: "Gain valuable insights into your website's performance",
  },
];

function Steps() {
  return (
    <div>
      <StepList title="Steps" data={DATA_STEPS} />
    </div>
  );
}

export default Steps;
