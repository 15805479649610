import classNames from "classnames";
import { Popover } from "antd";
import { AiOutlineProfile } from "react-icons/ai";
import { HiOutlineDocumentText } from "react-icons/hi";
import { VscCircuitBoard } from "react-icons/vsc";
import { AiOutlineMessage } from "react-icons/ai";
import { Typography } from "antd";
import {
  AuthContext,
  IAuthContext,
} from "../../../../auth/AuthContextProvider";
import React from "react";
import {
  SignInMenuComp,
  SignOutMenuComp,
} from "../../../../auth/AuthComponents";
import { Link } from "react-router-dom";

interface IStep {
  icon: any;
  title: string;
  link: string;
}

const DATA_STEPS: IStep[] = [
  {
    icon: <AiOutlineProfile />,
    title: "Order History",
    link: "/service/hardware/order-history",
  },
];

type AppUserOptionsProps = {
  icon: any;
  title: string;
  link: string;
};

const AppUserOptions = ({ icon, title, link }: AppUserOptionsProps) => {
  return (
    <Link to={link} className="w-full">
      <div className="p-2 inline-flex gap-3 items-center group hover:cursor-pointer hover:bg-gray-200/50 w-full transition-primary">
        <div className="text-lg group-hover:text-blue-500">{icon}</div>
        <div className="group-hover:text-blue-500">{title}</div>
      </div>
    </Link>
  );
};

const SignInPopoverContent = () => {
  return (
    <div className="w-[200px] p-1">
      <div className="flex flex-col gap-2 items-center">
        <SignOutMenuComp />
      </div>
      <div className="pt-3">
        {DATA_STEPS.map((item) => (
          <AppUserOptions
            key={item.title}
            icon={item.icon}
            title={item.title}
            link={item.link}
          />
        ))}
      </div>
    </div>
  );
};

const SignOutComp: React.FC<{ userName?: string }> = (props) => {
  return (
    <Popover content={SignInPopoverContent}>
      <Typography.Text>{props.userName}</Typography.Text>
    </Popover>
  );
};

function HeaderAppUser() {
  return (
    <div
      className={classNames({
        "font-semibold text-[17px] text-gray-500 w-[100px] h-[50px] flex items-center justify-center":
          true,
        "hover:text-blue-500 hover:cursor-pointer": true,
      })}
    >
      <AuthContext.Consumer>
        {(auth: IAuthContext) =>
          auth.loggedIn ? (
            <SignOutComp userName={auth.user?.user} />
          ) : (
            <SignInMenuComp />
          )
        }
      </AuthContext.Consumer>
    </div>
  );
}

export default HeaderAppUser;
