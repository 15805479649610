// Hooks / Node modules / Styles
import DefaultLayout from "../../../../layouts/DefaultLayout";

// Reducers / Actions

// Utils / Functions

// Components

// Sub-Components
import Hero from "./_com/Hero";
import Description from "./_com/Description";
import Steps from "./_com/Steps";
import PcbDesignForm from "./_com/form/PcbDesignForm";
import {AuthContext, IAuthContext} from "../../../../auth/AuthContextProvider";
import React from "react";
import {SignIn} from "../../../../auth/AuthComponents";

// Data / Images / Icons

function PcbDesign() {
  return (
    <DefaultLayout>
      <div>
        <Hero />
        <Description />
        <Steps />
        <AuthContext.Consumer>{(auth:IAuthContext)=>auth.loggedIn?<PcbDesignForm/>:<SignIn/>}</AuthContext.Consumer>
      </div>
    </DefaultLayout>
  );
}

export default PcbDesign;
