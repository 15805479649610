import classNames from "classnames";
import { Link } from "react-router-dom";
import { Button as ANTDButton } from "antd";
import { PulseLoader } from "react-spinners";

type ANTDButtonWrapperProps = {
  text?: string;
  icon?: any;
  customStyles?: string;
  isLoading?: boolean;
  to?: string;
  type?: any;
  htmlType?: any;
  children?: any;
  onClick?: any;
};

const ANTDButtonWrapper = ({
  text,
  icon,
  customStyles,
  isLoading,
  onClick,
  type = "primary",
  htmlType = "button",
  children,
}: ANTDButtonWrapperProps) => {
  return (
    <ANTDButton
      onClick={onClick}
      type={type}
      htmlType={htmlType}
      className={classNames({
        "bg-gray-500 px-8 py-5 flex items-center justify-center w-fit": true,
        [`${customStyles}`]: customStyles,
      })}
      icon={icon}
      disabled={isLoading}
    >
      {isLoading && <PulseLoader loading={isLoading} size={5} />}
      {text}
      {children}
    </ANTDButton>
  );
};

type Props = {
  onClick?: any;
  text?: string;
  icon?: any;
  customStyles?: string;
  isLoading?: boolean;
  to?: string;
  type?: any;
  htmlType?: any;
  children?: any;
};

function Button({
  text,
  icon,
  customStyles,
  isLoading,
  onClick,
  to,
  type = "primary",
  htmlType = "button",
  children,
}: Props) {
  if (to) {
    return (
      <Link to={to}>
        <ANTDButtonWrapper
          onClick={onClick}
          type={type}
          htmlType={htmlType}
          customStyles={customStyles}
          isLoading={isLoading}
          text={text}
          icon={icon}
        >
          {children}
        </ANTDButtonWrapper>
      </Link>
    );
  } else {
    return (
      <ANTDButtonWrapper
        onClick={onClick}
        type={type}
        htmlType={htmlType}
        customStyles={customStyles}
        isLoading={isLoading}
        text={text}
        icon={icon}
      >
        {children}
      </ANTDButtonWrapper>
    );
  }
}

export default Button;
