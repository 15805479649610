// Hooks / Node modules / Styles
import DefaultLayout from "../../layouts/DefaultLayout";

// Reducers / Actions

// Utils / Functions

// Components

// Sub-Components

// Data / Images / Icons

function FAQ() {
  return (
    <DefaultLayout>
      <div>FAQ</div>
    </DefaultLayout>
  )
}

export default FAQ