import { Info } from "../../../../../../components";

type Props = {
  title?: string;
  infoContent?: any;
  children?: any;
};

function OptionRow({ title, infoContent, children }: Props) {
  return (
    <div className="flex flex-col md:flex-row gap-3 py-3 w-full">
      <div className="w-[200px] inline-flex gap-2 md:justify-between items-center">
        <div className="font-semibold text-[15px] text-gray-700">{title}</div>

        <Info infoContent={infoContent} />
      </div>
      <div>{children}</div>
    </div>
  );
}

export default OptionRow;