import axios from "axios";
import { Auth } from "../auth/Auth";
import { notification } from "antd";

interface TokenResponse {
  access_token: string;
  refresh_token: string;
}

if (process.env.REACT_APP_SERVER_URL) {
  axios.defaults.baseURL =
    process.env.REACT_APP_NODE_ENV === "production"
      ? process.env.REACT_APP_SERVER_URL
      : process.env.REACT_APP_SERVER_URL_DEV;
  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.common["Access-Control-Allow-Headers"] = "*";
}

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      Auth.signOut();
      notification.error({ message: "Session expired. Please sign in again." });
      window.location.href = "/"; // Redirect to home or login page
    }
    return Promise.reject(error);
  }
);

export const contactUs = async (info?: any) => {
  const { data } = await axios.post("/api/v1/contact_us", info);
  return data;
};

export const getServices = async () => {
  const { data } = await axios.get("/api/v1/services");
  return data;
};

export const uploadPCBDesign = async (data: FormData) => {
  const token = await Auth.getToken();
  return await axios.post("/api/upload/pcb_design", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const uploadPCBAssembly = async (data: FormData) => {
  const token = await Auth.getToken();
  return await axios.post("/api/upload/pcb_assembly", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const uploadPCBFabrication = async (data: any) => {
  const token = await Auth.getToken();
  return await axios.post("/api/upload/pcb_fabrication", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const uploadBOM = async (data: FormData) => {
  const token = await Auth.getToken();
  return await axios.post("/api/upload/bom", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const authCfg = async () => {
  const { data } = await axios.get("/api/auth/cfg");
  return data;
};

export const userInfo = async () => {
  const token = await Auth.getToken();
  return axios.get("/api/v2/userinfo", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getQuotation = async (data: any) => {
  const { data: response } = await axios.post("/api/v1/get_quotation", data);
  return response;
};

export const uploadGerberFile = async (data: FormData) => {
  const { data: response } = await axios.post(
    "/api/upload/gerberValidate",
    data
  );
  return response;
};
export const uploadBomFile = async (data: FormData) => {
  const { data: response } = await axios.post(
    "/api/upload/upload_assembly_files",
    data
  );
  return response;
};

export const extractBOMData = async (data: FormData) => {
  const { data: response } = await axios.post("/api/extract/bom_data ", data);
  return response;
};

export const bomCplValidation = async (data: any) => {
  const { data: response } = await axios.post("api/validate/gerber", data);
  return response;
};


export const signInWIthCode = async (
  code: string,
  redirectURI: string
): Promise<TokenResponse> => {
  const { data } = await axios.post("/api/auth/connect", {
    grant_type: "authorization_code",
    redirect_uri: redirectURI,
    code: code,
  });
  return data;
};

export const refreshToken = async (token: string): Promise<TokenResponse> => {
  const { data } = await axios.post("/api/auth/connect", {
    grant_type: "refresh_token",
    refresh_token: token,
  });
  return data;
};

const CORS_PROXY = "https://cors-anywhere.herokuapp.com/";

export const uploadGerberToPCBWay = async (data: FormData) => {
  return await axios.post(
    CORS_PROXY + "https://www.pcbway.com/common/upfile/",
    data,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
};

export const convertGerberToPng = async (pcbFilePath: string) => {
  const formData = new FormData();
  formData.append("PcbFilePath", pcbFilePath);

  const response = await axios.post(
    CORS_PROXY + "https://www.pcbway.com/Common/GerberToPng",
    formData,
    {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }
  );
  return response.data;
};


