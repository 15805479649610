// Hooks / Node modules / Styles

// Reducers / Actions

// Utils / Functions

// Components
import DefaultLayout from "../../../../layouts/DefaultLayout";

// Sub-Components
import Hero from "./_com/Hero";
import Description from "./_com/Description";
import Offerings from "./_com/Offerings";
import Examples from "./_com/Examples";
import ContactRedirect from "./_com/ContactRedirect";

// Data / Images / Icons

function IoTSolutions() {
  return (
    <DefaultLayout>
      <div>
        <Hero />
        <Description />
        <Offerings/>
        <Examples/>
        <ContactRedirect/>
      </div>
    </DefaultLayout>
  );
}

export default IoTSolutions;
