import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { Layout } from "antd";

import { Header, Content, Footer } from "../components";

import {logo_whitebg} from "../assets"



type Props = {
  children: JSX.Element;
};

function DefaultLayout({ children }: Props) {
  return (
    <Layout>
      {/* Header */}
      <Header />

      {/* Content */}
      <Content>{children}</Content>

      {/* Footer */}
      <Footer />
      <FloatingWhatsApp phoneNumber='+94 70 235 1451' accountName='incbotic pvt ltd' avatar={logo_whitebg}/>
    </Layout>
  );
}

export default DefaultLayout;
