// Hooks / Node modules / Styles
import DefaultLayout from "../../../../layouts/DefaultLayout";

// Reducers / Actions

// Utils / Functions

// Components

// Sub-Components
import Hero from "./_com/Hero";
import Steps from "./_com/Steps";
import BOMForm from "./_com/form/BOMForm";
import BOMOptions from "./_com/BOMOptions";
import {
  AuthContext,
  IAuthContext,
} from "../../../../auth/AuthContextProvider";
import {SignIn} from "../../../../auth/AuthComponents";
import React from "react";

// Data / Images / Icons

function BomService() {
  return (
    <DefaultLayout>
      <div>
        <Hero />
        <Steps />
        <AuthContext.Consumer>
          {(auth: IAuthContext) => auth.loggedIn?<BOMForm />:<SignIn/>}
        </AuthContext.Consumer>
        <BOMOptions />
      </div>
    </DefaultLayout>
  );
}

export default BomService;
