// Hooks / Node modules / Styles
import DefaultLayout from "../../../layouts/DefaultLayout";

// Reducers / Actions

// Utils / Functions

// Components

// Sub-Components

// Data / Images / Icons

function ForgotPassword() {
  return (
    <DefaultLayout>
      <div>ForgotPassword</div>
    </DefaultLayout>
  );
}

export default ForgotPassword;
