import React from "react"
import { Layout } from "antd";

import { Header, Content, Footer } from "../components";

type Props = {
  children: JSX.Element;
};

function DashboardLayout({ children }: Props) {
  return (
    <Layout>
      {/* Header */}
      <Header />

      {/* Content */}
      <Content>{children}</Content>

      {/* Footer */}
      <Footer />
    </Layout>
  );
}

export default DashboardLayout;
