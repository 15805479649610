import { Button } from "../../../../../components";

function ContactRedirect() {
  return (
    <div className="bg-bg_calm_splines bg-cover bg-no-repeat bg-center padding-x py-[50px] md:py-[100px] flex flex-col md:flex-row items-center justify-center gap-5 md:gap-10">
      <div className="text-section">Contact us today for a free consultation. Let's bring your vision to life and drive your business forward.</div>
      <Button text="Contact Us" to="/contact-us" />
    </div>
  );
}

export default ContactRedirect;
