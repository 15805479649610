import { Layout } from "antd";

function Footer() {
  return <Layout.Footer className="bg-gray-500">
    <div className="text-gray-200 font-semibold text-center">
    All Rights Reserved | Incbotic (Pvt) Ltd.
    </div>
  </Layout.Footer>;
}

export default Footer;
