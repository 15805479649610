import { Drawer, Space, Menu } from "antd";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { logo } from "../../../../assets";
import {
  AppstoreOutlined,
  DesktopOutlined,
  // DeploymentUnitOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

type Props = {
  open?: any;
  onClose?: any;
};

function HeaderNavigationHamburgerMenu({ open, onClose }: Props) {
  const location = useLocation();
  const pathnameSegments = location.pathname.split("/");

  return (
    <Drawer
      placement="right"
      width={350}
      onClose={onClose}
      open={open}
      extra={
        <Space>
          <div className="inline-flex items-center justify-center gap-2">
            <img src={logo} alt="logo" className="w-[45px] h-[40px]" />
            <div className="text-xl font-bold text-gray-700">Incbotic</div>
          </div>
        </Space>
      }
    >
      <Menu
        mode="inline"
        selectedKeys={[pathnameSegments[1]]}
        className="w-ful font-semibold text-md text-gray-600"
      >
        <Menu.SubMenu key="service" icon={<AppstoreOutlined />} title="Service">
          <div className="flex flex-col gap-3 p-3">
            <div>
              <div className="font-semibold text-gray-400 py-4">Hardware</div>
              <Menu.Item key="service:hardware:custom_design">
                <Link to="/service/hardware/custom-solutions">
                  Custom Solutions
                </Link>
              </Menu.Item>
              <Menu.Item key="service:hardware:pcb_design">
                <Link to="/service/hardware/pcb-design">PCB Design</Link>
              </Menu.Item>
              <Menu.Item key="service:hardware:pcb_fabrication">
                <Link to="/service/hardware/pcb-fabrication">
                  PCB Fabrication
                </Link>
              </Menu.Item>
              <Menu.Item key="service:hardware:pcb_assembly">
                <Link to="/service/hardware/pcb-assembly">PCB Assembly</Link>
              </Menu.Item>
              <Menu.Item key="service:hardware:bom_service">
                <Link to="/service/hardware/bom-service">BOM Service</Link>
              </Menu.Item>
            </div>
            <div>
              <div className="font-semibold text-gray-400 py-4">Software</div>
              <Menu.Item key="service:software:web_solutions">
                <Link to="/service/software/web-solutions">Web Solutions</Link>
              </Menu.Item>
              {/* <Menu.Item key="service:software:cloud_solutions">
              <Link to="/service/software/cloud-solutions">
                Cloud Solutions
              </Link>
            </Menu.Item> */}
            </div>
          </div>
        </Menu.SubMenu>
        <Menu.Item key="careers" icon={<DesktopOutlined />}>
          <Link to="/careers">Careers</Link>
        </Menu.Item>
        <Menu.SubMenu
          key="about-us"
          icon={<EnvironmentOutlined />}
          title="About Us"
        >
          <Menu.Item key="about_us:vision_mission_and_value">
            <Link to="/about-us/vision-mission-and-value">
              Vision, Mission and Value
            </Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item key="contact-us" icon={<PhoneOutlined />}>
          <Link to="/contact-us">Contact Us</Link>
        </Menu.Item>
      </Menu>
    </Drawer>
  );
}

export default HeaderNavigationHamburgerMenu;
