import classNames from "classnames";
import { motion } from "framer-motion";
import { fadeIn } from "../../../../../utils/motion";

type StepCardProps = {
  index: number;
  icon?: any;
  image?: any;
  title?: string;
  description?: string;
  customStyles?: string;
};

function StepCard({
  index,
  icon,
  image,
  title,
  description,
  customStyles,
}: StepCardProps) {
  return (
    <motion.div
      variants={fadeIn("up", "spring", index * 0.5, 0.75)}
      className={classNames({
        "flex flex-col items-center justify-center my-5": true,
        [`${customStyles}`]: customStyles,
      })}
    >
      {icon && <div className="text-[50px] text-gray-800 pb-1">{icon}</div>}

      {image && (
        <img
          src={image}
          alt="step_icon"
          className="w-[170px] h-[150px] object-cover"
        />
      )}

      {title && (
        <div className="text-[25px] font-semibold text-gray-800 text-center w-full">
          {title}
        </div>
      )}

      {description && (
        <div className="text-[17px] font-thin text-gray-700 text-center w-full">
          {description}
        </div>
      )}
    </motion.div>
  );
}

export default StepCard;
