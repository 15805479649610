// Home
import Home from "./home/Home";

// Authentication
import Login from "./auth/login/Login";
import Signup from "./auth/sign-up/Signup";
import ForgotPassword from "./auth/forgot-password/ForgotPassword";

// Service
// Hardware
import CustomSolutions from "./service/hardware/custom-solutions/CustomSolutions";
import PcbDesign from "./service/hardware/pcb-design/PcbDesign";
import PcbFabrication from "./service/hardware/pcb-fabrication/PcbFabrication";
import PcbAssmebly from "./service/hardware/pcb-assembly/PcbAssmebly";
import BomService from "./service/hardware/bom-service/BomService";
// Software
import WebSolutions from "./service/software/web-solutions/WebSolutions";
import CloudSolutions from "./service/software/cloud-solutions/CloudSolutions";
// IoT
import IoTSolutions from "./service/iot/iot-solutions/IoTSolutions";
import LargeScaleManufacturing from "./service/iot/large-scale-manufacturing/LargeScaleManufacturing";

// Products
import SmartProduct from "./products/smart-product/SmartProduct";
import Experience from "./products/experience/Experience";
import SmartLife from "./products/smart-life/SmartLife";

// Careers
import Careers from "./careers/Careers";

// About Us
import VisionMissionAndValue from "./about-us/vision-mission-and-value/VisionMissionAndValue";
import Location from "./about-us/location/Location";

// Contact Us
import ContactUs from "./contact-us/ContactUs";

// FAQ
import FAQ from "./faq/FAQ";

// Dashboard
import Dashboard from "./dashboard/Dashboard";
import OrderHistory from "./OrderHistory";

const Pages = {
  Home,

  Login,
  Signup,
  ForgotPassword,

  // Hardware
  CustomSolutions,
  PcbDesign,
  PcbFabrication,
  PcbAssmebly,
  BomService,
  // Software
  WebSolutions,
  CloudSolutions,
  // IoT
  IoTSolutions,
  LargeScaleManufacturing,

  SmartProduct,
  Experience,
  SmartLife,

  Careers,

  VisionMissionAndValue,
  Location,

  ContactUs,

  FAQ,

  Dashboard,
  OrderHistory,
};

export default Pages;
