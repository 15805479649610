import React, { useState, useEffect } from "react";
import {
  Form,
  Upload,
  UploadProps,
  message,
  Button as AntButton,
  Table,
  Space,
  Select,
  Checkbox,
  Input,
  Tooltip,
  Button,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { AiOutlineUnlock } from "react-icons/ai";
import { useMutation } from "react-query";
import {
  extractBOMData,
  uploadBomFile,
} from "../../../../../../../../../api/api"; // Adjust this import path as needed

const { Dragger } = Upload;
const { Option } = Select;

interface Component {
  id: number;
  comment: string;
  description: string;
  footprint: string;
  unit_cost: string;
  selling_price: string;
  type: string;
}

interface OurComponent {
  type: string;
  comment: string;
  component_id: string;
}

const ComponentSelector: React.FC<{
  allComponents: Component[];
  onChange: (value: OurComponent & { unit_price: string }) => void;
  value: OurComponent & { unit_price: string };
}> = ({ allComponents, onChange, value }) => {
  const [isOtherChecked, setIsOtherChecked] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState<
    string | undefined
  >(value.component_id);
  const [inputComponent, setInputComponent] = useState(value.comment);

  useEffect(() => {
    if (!isOtherChecked && value.component_id) {
      setSelectedComponent(value.component_id);
    } else if (!isOtherChecked && allComponents && allComponents.length > 0) {
      setSelectedComponent(allComponents[0].id.toString());
      onChange({
        type: allComponents[0].type || "SMD",
        comment: allComponents[0].comment,
        component_id: allComponents[0].id.toString(),
        unit_price: allComponents[0].selling_price,
      });
    }
  }, [isOtherChecked, allComponents, onChange, value]);

  const handleComponentChange = (value: string) => {
    setSelectedComponent(value);
    const component = allComponents.find((c) => c.id.toString() === value);
    if (component) {
      onChange({
        type: component.type || "SMD",
        comment: component.comment,
        component_id: component.id.toString(),
        unit_price: component.selling_price,
      });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputComponent(e.target.value);
    onChange({
      type: "",
      comment: e.target.value,
      component_id: "",
      unit_price: "",
    });
  };

  return (
    <Space direction="horizontal">
      {isOtherChecked ? (
        <Input
          placeholder="Enter component name"
          style={{ width: 150 }}
          value={inputComponent}
          onChange={handleInputChange}
        />
      ) : (
        <Select
          style={{ width: 150 }}
          placeholder="Search and select"
          showSearch
          optionFilterProp="children"
          value={selectedComponent}
          onChange={handleComponentChange}
        >
          {allComponents.map((component) => (
            <Option key={component.id} value={component.id.toString()}>
              {component.comment}
            </Option>
          ))}
        </Select>
      )}
      <Checkbox onChange={(e) => setIsOtherChecked(e.target.checked)}>
        Request
      </Checkbox>
    </Space>
  );
};

function BomUploadArea({ pcbForm }: { pcbForm: any }) {
  const [responseData, setResponseData] = useState<any>(null);
  const [tableData, setTableData] = useState<any[]>([]);

  const mutationBom = useMutation(extractBOMData, {
    onSuccess: (data) => {
      message.success("File uploaded successfully");
      setResponseData(data.response);
      setTableData(transformResponseData(data.response));
      console.log(data.response);
    },
    onError: (error) => {
      message.error("Upload failed");
      console.error("Upload error:", error);
    },
  });

  const uploadMutation = useMutation(uploadBomFile, {
    onSuccess: (data: any) => {
      message.success("File uploaded successfully");
      console.log("Upload bom:", data.uuid);
      pcbForm.setFieldsValue({ bomFileId: data.uuid });
    },
    onError: (error) => {
      message.error("Failed to upload file");
      console.error("Upload error:", error);
    },
  });

  const props: UploadProps = {
    beforeUpload: (file) => {
      const allowedExtensions = [".xls", ".xlsx", ".txt", ".csv"];
      const maxSize = 10 * 1024 * 1024; // 10 MB

      const fileExtension = file.name
        .toLowerCase()
        .substring(file.name.lastIndexOf("."));
      const isAllowedExtension = allowedExtensions.includes(fileExtension);

      if (!isAllowedExtension) {
        message.error(
          `${
            file.name
          } is not allowed. Allowed extensions are: ${allowedExtensions.join(
            ", "
          )}`
        );
        return Upload.LIST_IGNORE;
      }

      if (file.size > maxSize) {
        message.error(
          `${file.name} is too large. Maximum size allowed is 10MB`
        );
        return Upload.LIST_IGNORE;
      }

      return true;
    },
  };

  const handleUpload = async (info: any) => {
    const { file, onSuccess, onError } = info;
    const formData = new FormData();
    formData.append("bom", file);

    try {
      await mutationBom.mutateAsync(formData);
      onSuccess("Ok");
    } catch (err) {
      onError({ err });
    }
    try {
      await uploadMutation.mutateAsync(formData);
      onSuccess("Ok");
    } catch (err) {
      onError({ err });
    }
  };

  const transformResponseData = (data: any) => {
    return data.bom_data.map((item: any, index: number) => {
      const matchingComponent = data.our_component_list.find(
        (comp: any) => comp.comment === item.Comment
      );

      const unitPrice = matchingComponent
        ? parseFloat(matchingComponent.unit_cost)
        : 0;
      const quantity = parseInt(item.Quantity, 10) || 0;
      const linePrice = unitPrice * quantity;

      return {
        key: index.toString(),
        req: {
          description: matchingComponent
            ? "Components verified"
            : "Components pending",
          req_complete: !!matchingComponent,
        },
        comment: item.Comment,
        footprint: item.Footprint,
        line_price: linePrice,
        unit_price: unitPrice,
        description: item.Description,
        designators: item.Designator,
        qty: quantity,
        our_component: matchingComponent
          ? {
              type: matchingComponent.type || "SMD",
              comment: matchingComponent.comment,
              component_id: matchingComponent.id.toString(),
              unit_price: matchingComponent.unit_cost,
            }
          : {
              type: "",
              comment: "",
              component_id: "",
              unit_price: "",
            },
        allComponents: data.our_component_list || [],
        soldering: false,
      };
    });
  };

  const getOrganizedData = () => {
    if (!tableData || tableData.length === 0) {
      console.error("Table data is not available");
      return [];
    }

    return tableData.map((item: any) => {
      return {
        req: {
          description: item.our_component.type
            ? "Components verified"
            : "Components pending",
          req_complete: !!item.our_component.type,
        },
        qty: item.qty,
        comment: item.comment,
        footprint: item.footprint,
        line_price: item.line_price,
        unit_price: item.unit_price,
        description: item.description,
        designators: item.designators,
        our_component: item.our_component,
        soldering: item.soldering,
      };
    });
  };

  const handleGetOrganizedData = () => {
    const organizedData = getOrganizedData();
    pcbForm.setFieldsValue({ assemblyData: organizedData });
  };

  const handleSolderingChange = (checked: boolean, record: any) => {
    const updatedTableData = tableData.map((item) => {
      if (item.key === record.key) {
        return {
          ...item,
          soldering: checked,
        };
      }
      return item;
    });
    setTableData(updatedTableData);
  };

  const columns = [
    {
      title: "Select",
      dataIndex: "soldering",
      key: "soldering",
      width: 60,
      render: (_: any, record: any) => (
        <Checkbox
          checked={record.soldering}
          onChange={(e) => handleSolderingChange(e.target.checked, record)}
        />
      ),
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      width: 80,
      render: (text: string) => (
        <Tooltip title={text}>
          <div
            style={{
              maxWidth: 80,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Desig.",
      dataIndex: "designators",
      key: "designators",
      width: 60,
      render: (text: string) => (
        <Tooltip title={text}>
          <div
            style={{
              maxWidth: 60,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Desc.",
      dataIndex: "description",
      key: "description",
      width: 60,
      render: (text: string) => (
        <Tooltip title={text}>
          <div
            style={{
              maxWidth: 60,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Footprint",
      dataIndex: "footprint",
      key: "footprint",
      width: 60,
      render: (text: string) => (
        <Tooltip title={text}>
          <div
            style={{
              maxWidth: 60,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    { title: "QTY", dataIndex: "qty", key: "qty", width: 40 },
    { title: "Price", dataIndex: "unit_price", key: "unit_price", width: 50 },
    {
      title: "Incbotic Component",
      key: "our_component",
      width: 200,
      render: (_: any, record: any) => (
        <ComponentSelector
          allComponents={record.allComponents || []}
          value={record.our_component}
          onChange={(value) => {
            const updatedTableData = tableData.map((item) => {
              if (item.key === record.key) {
                const newUnitPrice = parseFloat(value.unit_price) || 0;
                const newLinePrice = newUnitPrice * item.qty;
                return {
                  ...item,
                  our_component: value,
                  unit_price: newUnitPrice,
                  line_price: newLinePrice,
                };
              }
              return item;
            });
            setTableData(updatedTableData);
          }}
        />
      ),
    },
  ];

  return (
    <div style={{ margin: 20 }}>
      <Form.Item name="bomFileId"></Form.Item>
      <Form.Item
        name="bomFile"
        valuePropName="fileList"
        getValueFromEvent={(e) => {
          if (Array.isArray(e)) {
            return e;
          }
          return e?.fileList;
        }}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              const isPcbAssembly = getFieldValue("IsPcbAssembly");
              if (isPcbAssembly && (!value || value.length === 0)) {
                return Promise.reject("BOM file is required");
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Dragger {...props} customRequest={handleUpload}>
          <div className="p-5 flex flex-col items-center justify-center">
            <AntButton
              type="primary"
              shape="round"
              size="large"
              icon={<UploadOutlined />}
            >
              Add BOM file
            </AntButton>
            <p className="ant-upload-text pt-2">
              Click or drag file to this area to upload
            </p>
            <div className="flex flex-col md:flex-row gap-3 pt-3">
              <p className="ant-upload-hint">
                Only accept .xls, .xlsx, .txt or .csv.
              </p>
              <p className="ant-upload-hint inline-flex gap-2 items-center justify-center">
                <div className="text-gray-700 font-semibold">
                  <AiOutlineUnlock />
                </div>
                <span>All uploads are secure and confidential</span>
              </p>
            </div>
            <Button
              style={{ marginTop: 8 }}
              type="link"
              onClick={() => {
                const bomdownloadUrl = `https://sampledataformatbucket.s3.ap-south-1.amazonaws.com/BOM_SampleFormat.xlsx`;
                window.open(bomdownloadUrl, "_blank");
              }}
            >
              Dowload sample BOM
            </Button>
          </div>
        </Dragger>
      </Form.Item>

      {responseData && (
        <>
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            bordered
            size="small"
            scroll={{ x: 710 }} // Adjust this value based on the sum of column widths
            style={{ marginTop: "1rem" }}
          />
          <AntButton
            onClick={handleGetOrganizedData}
            style={{ marginTop: "1rem" }}
          >
            Get Assembly quotation
          </AntButton>
        </>
      )}
    </div>
  );
}

export default BomUploadArea;
