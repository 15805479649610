import classNames from "classnames";

import { Button } from "../../../../../components";

function Hero() {
  return (
    <div className="bg-circuit_diagram bg-cover bg-no-repeat bg-center">
      <section
        className={classNames({
          "relative w-full h-fit padding-x py-14": true,
          "bg-gradient-to-r from-stone-900 via-neutral-800/90 to-gray-700/10":
            true,
        })}
      >
        <div className="w-full md:w-[800px]">
          <div className="text-chapter pb-5  text-gray-300">
            PCB Design
          </div>
          <div className="flex flex-col gap-5">
            <div className="text-section  text-gray-300">
            Navigating the Journey of Design to Layout
            </div>
            <Button text="Contact Us" to="/contact-us" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Hero;
