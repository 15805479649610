import {
  cs_1_idea,
  cs_2_refine,
  cs_3_feasibility,
  cs_4_design,
  cs_5_prototype,
  cs_6_optimize,
  cs_7_factory,
} from "../../../../../assets";

import { StepList } from "../../../../../components";

interface IStep {
  image: any;
  title: string;
  description: string;
}

const DATA_STEPS: IStep[] = [
  {
    image: cs_1_idea,
    title: "Idea",
    description: "Share your concept.",
  },
  {
    image: cs_2_refine,
    title: "Refine",
    description: "We collaborate to fine-tune your idea. ",
  },
  {
    image: cs_3_feasibility,
    title: "Feasibility",
    description:
      "We evaluate the project viability.",
  },
  {
    image: cs_4_design,
    title: "Design",
    description: "Our experts create a functional design.",
  },
  {
    image: cs_5_prototype,
    title: "Prototype",
    description: "We build and test a prototype.",
  },
  {
    image: cs_6_optimize,
    title: "Optimize",
    description: "Iterations enhance design and performance.",
  },
  {
    image: cs_7_factory,
    title: "Finalize",
    description: "We prepare for manufacturing.",
  },
];

function Steps() {
  return (
    <div>
      <StepList title="Steps" data={DATA_STEPS} />
    </div>
  );
}

export default Steps;
