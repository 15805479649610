import { Col, Row } from "antd";
import classNames from "classnames";

import StepCard from "./_com/StepCard";

interface IStep {
  icon?: any;
  image?: any;
  title?: string;
  description?: string;
}

type StepListProps = {
  title?: string;
  data?: IStep[];
  gutter?: number;
  cols?: number;
  customStyles?: string;
};

function StepList({ title, data, gutter, cols, customStyles }: StepListProps) {
  return (
    <div
      className={classNames({
        "padding-x padding-y bg-gray-200": true,
        [`${customStyles}`]: customStyles,
      })}
    >
      {title && (
        <div className="text-[35px] font-bold text-gray-800 text-center pb-5 md:pb-[40px]">
          {title}
        </div>
      )}

      <Row gutter={gutter ? gutter : 10} className="justify-center">
        {data?.map((item, index) => {
          return (
            <Col xl={cols ? cols : 6} className="w-full">
              <StepCard
                key={index}
                index={index}
                icon={item?.icon}
                image={item?.image}
                title={item?.title}
                description={item?.description}
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default StepList;
