// Hooks / Node modules / Styles

// Reducers / Actions

// Utils / Functions

// Components
import DefaultLayout from "../../layouts/DefaultLayout";

// Sub-Components
import Hero from "./_com/Hero";

// Data / Images / Icons

function Home() {
  return (
    <DefaultLayout>
      <div>
        <Hero />
      </div>
    </DefaultLayout>
  );
}

export default Home;
