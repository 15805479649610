import { Layout } from "antd";

type Props = {
  children: JSX.Element;
};

function Content({ children }: Props) {
  return <Layout.Content className="min-h-screen pt-[64px]"><div>{children}</div></Layout.Content>;
}

export default Content;
