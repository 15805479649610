// Hooks / Node modules / Styles
import { Form, Upload } from "antd";
import { Button } from "../../../../../../../components";

// Reducers / Actions

// Utils / Functions

// Components

// Sub-Components

// Data / Images / Icons
import { UploadOutlined } from "@ant-design/icons";
import { AiOutlineUnlock } from "react-icons/ai";

const { Dragger } = Upload;

function GerberUploadArea({ rules }: any) {
  const normFile = (e: any) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <div>
      <Form.Item
        name="gerber"
        label="Upload Gerber File"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={rules}
        required
      >
        {/*<div className="text-[20px] font-bold text-gray-600 text-center pb-3 md:pb-[20px]">*/}
        {/*    Upload Gerber File*/}
        {/*  </div>*/}
        <Dragger>
          <div className="p-5 flex flex-col items-center justify-center">
            <Button icon={<UploadOutlined />} text="Add gerber file" />
            <p className="ant-upload-text pt-2">
              Click or drag file to this area to upload
            </p>
            <div className="flex flex-col md:flex-row gap-3 pt-3">
              <p className="ant-upload-hint">
                Only accept .zip or .rar.
              </p>
              <p className="ant-upload-hint inline-flex gap-2 items-center justify-center">
                <div className="text-gray-700 font-semibold">
                  <AiOutlineUnlock />
                </div>
                <span>All uploads are secure and confidential</span>
              </p>
            </div>
          </div>
        </Dragger>
      </Form.Item>
    </div>
  );
}

export default GerberUploadArea;