import { Popover } from "antd";

import { AiOutlineInfoCircle } from "react-icons/ai";

type Props = {
  infoContent?: any,
  children?: any,
};

function Info({ children, infoContent }: Props) {
  return (
    <Popover content={infoContent}>
      {children ? children : <div className="text-[18px] text-gray-400">
        <AiOutlineInfoCircle />
      </div>}
    </Popover>
  );
}

export default Info;
