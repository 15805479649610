import { StepList } from "../../../../../components";

import { MdEngineering } from "react-icons/md";
import { RiTeamLine } from "react-icons/ri";
import { PiPlugsConnectedLight } from "react-icons/pi";
import { HiAcademicCap } from "react-icons/hi";
import { BiTimer } from "react-icons/bi";

interface ISteps {
  icon: any;
  title: string;
  description: string;
}

const DATA_WHYUS: ISteps[] = [
  {
    icon: <MdEngineering />,
    title: "Expertise",
    description:
      "Our experienced team of engineers is equipped to handle diverse electronic design projects.",
  },
  {
    icon: <RiTeamLine />,
    title: "Collaboration",
    description:
      "We work closely with you to refine your idea and ensure it aligns with your vision maintaining open and transparent communication.",
  },
  {
    icon: <PiPlugsConnectedLight />,
    title: "End-to-End",
    description:
      "From concept to production, we provide a complete range of design services.",
  },
  {
    icon: <HiAcademicCap />,
    title: "Innovation",
    description:
      "We leverage cutting-edge technology to create standout designs.",
  },
  {
    icon: <BiTimer />,
    title: "Timely",
    description:
      "Our streamlined process ensures on-time delivery without compromising quality.",
  },
];

function WhyUs() {
  return (
    <div>
     <StepList title="Why Us" data={DATA_WHYUS} gutter={15} cols={4} />
    </div>
  );
}

export default WhyUs;
