import DashboardLayout from "../../layouts/DashboardLayout";

function Dashboard() {
  return (
    <DashboardLayout>
      <div>Dashboard</div>
    </DashboardLayout>
  );
}

export default Dashboard;
