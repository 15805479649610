// Hooks / Node modules / Styles

// Reducers / Actions

// Utils / Functions

// Components
import DefaultLayout from "../../../../layouts/DefaultLayout";

// Sub-Components
import Hero from "./_com/Hero";
import Description from "./_com/Description";
import Steps from "./_com/Steps";
import ContactRedirect from "./_com/ContactRedirect";

// Data / Images / Icons

function LargeScaleManufacturing() {
  return (
    <DefaultLayout>
      <div>
        <Hero />
        <Description />
        <Steps />
        <ContactRedirect />
      </div>
    </DefaultLayout>
  );
}

export default LargeScaleManufacturing;
