import {
  Switch,
  Form,
  Image,
  Space,
  Typography,
  Collapse,
  Radio,
  Table,
  Select,
  Button,
  Row,
  Col,
  Checkbox,
  Input,
  message,
} from "antd";
import { img_pcb_assembly } from "../../../../../../../../assets";
import { useState } from "react";
import CplUploadArea from "./cpl-upload-area/CplUploadArea";
import BomUploadArea from "./bom-upload-area/BomUploadArea";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { useMutation } from "react-query";
import { uploadBomFile } from "../../../../../../../../api/api";
import { DownloadOutlined } from "@ant-design/icons";

const { Option } = Select;

const SIDES_ASSEMBLE: { value: string; label: string }[] = [
  { value: "Top", label: "Top" },
  { value: "Bottom", label: "Bottom" },
  { value: "Both", label: "Both" },
];

const MainContent = () => {
  return (
    <Form.Item
      name="sidesToAssemble"
      label="Sides To Assemble"
      style={{ marginLeft: "18px" }}
      rules={[
        ({ getFieldValue }) => ({
          validator(_, value) {
            const isPcbAssembly = getFieldValue("IsPcbAssembly");
            if (isPcbAssembly && (!value || value.length === 0)) {
              return Promise.reject("This field is required");
            }
            return Promise.resolve();
          },
        }),
      ]}
    >
      <Radio.Group buttonStyle="solid">
        {SIDES_ASSEMBLE.map(({ value, label }) => (
          <Radio.Button key={value} value={value}>
            {label}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

function OtherOptions({ pcbForm }: { pcbForm: any }) {
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  const { Text } = Typography;

  const handleSwitchChange = (checked: boolean) => {
    setIsCollapseOpen(checked);
  };

  return (
    <div className="p-5 pt-2">
      <Form.Item
        name="IsPcbAssembly"
        label={
          <Space>
            <Image
              width={40}
              preview={false}
              src={img_pcb_assembly}
              alt="img_pcb_assembly"
              style={{ marginTop: 14 }}
            />
            <Text>PCB Assembly</Text>
          </Space>
        }
        style={{ margin: 5, marginBottom: 20 }}
      >
        <Switch
          className="bg-gray-400"
          checked={isCollapseOpen}
          onChange={handleSwitchChange}
        />
      </Form.Item>
      <Collapse
        defaultActiveKey={["1"]}
        activeKey={isCollapseOpen ? ["1"] : []}
      >
        <Collapse.Panel
          disabled={!isCollapseOpen}
          key="1"
          header="Assembly Options"
        >
          <div style={{ display: "inline-flex" }}>
            <MainContent />
          </div>
          <BomUploadArea pcbForm={pcbForm} />

          <CplUploadArea pcbForm={pcbForm} />
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}

export default OtherOptions;
