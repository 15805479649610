function Description() {
  return (
    <div className="padding-x padding-y bg-gray-200 flex flex-col items-center text-center gap-8">
      <div className="text-section  text-gray-800 w-full md:w-[800px]">
        Are you ready to take your business to the next level? Looking for a
        partner to create an impressive online presence that captivates your
        audience and drives results? Look no further! We offer cutting-edge web
        solutions tailored to your unique needs.
      </div>
    </div>
  );
}

export default Description;
