// Hooks / Node modules / Styles

// Reducers / Actions

// Utils / Functions

// Components
import DefaultLayout from "../../../../layouts/DefaultLayout"

// Sub-Components

// Data / Images / Icons

function CloudSolutions() {
  return (
    <DefaultLayout><div>CloudSolutions</div></DefaultLayout>
  )
}

export default CloudSolutions