import { Button } from "../../../../../../../../components";
import { Form, Typography, Drawer, Input, notification, message } from "antd";
import { Button as ANTDButton } from "antd";
import { useContext, useEffect, useState } from "react";
import UploadForm from "../../../../../../../../utils/helpers/UploadForm";
import { uploadPCBFabrication } from "../../../../../../../../api/api";

import { useMutation } from "react-query";
import { AuthContext } from "../../../../../../../../auth/AuthContextProvider";
import { log } from "console";

type Props = {
  onClose?: any;
  formData: PCBFormData;
  toggleDrawer: () => void;
  pcbForm: any;
  quotationData: any;
  assemblyOrderdata: any;
  spcialReq: string;
};

interface QuotationData {
  pcbCost: number;
  shippingCost: number;
  totalCost: number;
}
type PCBFormData = {
  Length: number;
  Width: number;
  Layers: number;
  Qty: number;
  Thickness: number;
  Material: string;
  DesignInPanel: number;
  MinTrackSpacing: string;
  MinHoleSize: number;
  SolderMask: string;
  Silkscreen: string;
  SurfaceFinish: string;
  ViaProcess: string;
  FinishedCopper: string;
  FR4Tg: string;
  Goldfingers: string;
  assemblyData: any;
  buildDays: number;
  // Add any other properties your form data might have
};
function CustomerDetailsDrawer({
  toggleDrawer,
  formData,
  onClose,
  pcbForm,
  quotationData,
  assemblyOrderdata,
  spcialReq,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const { Text } = Typography;
  const [form] = Form.useForm();
  const auth = useContext(AuthContext);

  useEffect(() => {
    console.log("out", auth);

    if (auth.user?.email) {
      console.log("in", auth.user?.email);
      form.setFieldValue("email", auth.user.email);
    }
  }, [auth.user?.email]);

  const handleUseBillingInfo = () => {
    form.setFieldsValue({
      deliveryFullName: form.getFieldValue("billingFullName"),
      deliveryMobile: form.getFieldValue("billingMobile"),
      deliveryAddress: form.getFieldValue("billingAddress"),
      deliveryCity: form.getFieldValue("billingCity"),
      deliveryProvince: form.getFieldValue("billingProvince"),
    });
  };

  const { mutate, isLoading: isLoadingSubmit } = useMutation(
    uploadPCBFabrication,
    {
      onSuccess: (data) => {
        message.success("Order submitted successfully");
        // toggleDrawer();
        // window.location.reload();
      },
      onError: (error) => {
        message.error("Upload failed");
        console.error("Upload error:", error);
      },
    }
  );

  const onSubmit = async (values: any) => {
    const customerInfo = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      billing_info: {
        full_name: values.billingFullName,
        mobile: values.billingMobile,
        address: {
          first_line: values.billingAddress,
          city: values.billingCity,
          province: values.billingProvince,
        },
      },
      delivery_info: {
        full_name: values.deliveryFullName,
        mobile: values.deliveryMobile,
        address: {
          first_line: values.deliveryAddress,
          city: values.deliveryCity,
          province: values.deliveryProvince,
        },
      },
    };

    const fabricationDetails = {
      Length: formData.Length,
      Width: formData.Width,
      Layers: formData.Layers,
      Qty: formData.Qty,
      Thickness: formData.Thickness,
      Material: formData.Material,
      DesignInPanel: formData.DesignInPanel,
      MinTrackSpacing: formData.MinTrackSpacing,
      MinHoleSize: formData.MinHoleSize,
      SolderMask: formData.SolderMask,
      Silkscreen: formData.Silkscreen,
      SurfaceFinish: formData.SurfaceFinish,
      ViaProcess: formData.ViaProcess,
      FinishedCopper: formData.FinishedCopper,
      FR4Tg: formData.FR4Tg,
      Goldfingers: "No",
      buildDays: quotationData.buildDays || 0,
    };

    const assemblyData =
      assemblyOrderdata.sides_to_assemble !== undefined
        ? {
            assembly_details: {
              components: assemblyOrderdata.bom_data.components,
            },
            no_of_sides: assemblyOrderdata.no_of_sides,
            sides_to_assemble: assemblyOrderdata.sides_to_assemble,
            quoted_component_price:
              quotationData.assemblyPrice.quoted_component_price,
            quoted_assembly_price: {
              setup_cost: Number(quotationData.assemblyPrice.setup_cost),
              feeder_cost: quotationData.assemblyPrice.feeder_cost,
              stencil_cost: quotationData.assemblyPrice.stencil_cost,
              pcb_panal_cost: quotationData.assemblyPrice.pcb_panal_cost,
              smd_assembly_cost: quotationData.assemblyPrice.smd_assembly_cost,
              tht_assembly_cost: quotationData.assemblyPrice.tht_assembly_cost,
              total_assembly_cost: Number(
                quotationData.assemblyPrice.total_assembly_cost
              ),
            },
            bom_uuid: assemblyOrderdata.bomId,
            cpl_uuid: assemblyOrderdata.cplId,
          }
        : null;

    const requestData = {
      assemblyData: assemblyData,
      orderDetails: {
        special_requirements: spcialReq,
        customer_info: customerInfo,
      },
      isAssembly:
        assemblyOrderdata.sides_to_assemble !== undefined ? true : false,
      fabricationData: {
        fabrication_details: fabricationDetails,
        fabrication_partner: "PCBWay",
        gerberfile_id: pcbForm.getFieldValue("gerberId"),
        pcb_cost: quotationData.pcbCost,
        shipping_cost: quotationData.shippingCost,
      },
    };

    console.log(requestData, "fabrication", formData);

    try {
      await mutate(requestData);
    } catch (error) {
      console.error("Error submitting quotation:", error);
    }
  };

  return (
    <Drawer
      title="Customer Details"
      placement="right"
      onClose={onClose}
      visible={true}
      width={820}
    >
      <div>
        <UploadForm
          form={form}
          onSubmit={onSubmit}
          setIsLoading={setIsLoading}
          name="pcbFabricationCustomer"
          autoComplete="off"
          labelCol={{ span: 7 }}
          labelWrap={true}
          labelAlign={"left"}
        >
          <div className="border border-gray-300 p-2 pt-4 pb-0 rounded-md ">
            <Text className="text-sm font-medium">PERSONAL INFO</Text>
            <div className="md:flex items-center gap-2">
              <Form.Item
                className="mt-4 md:flex-1"
                labelCol={{ span: 6 }}
                name="firstName"
                label="First Name"
                rules={[
                  { required: true, message: "Please enter your first name." },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                className="md:flex-1 md:mt-4"
                name="lastName"
                label="Last Name"
                labelCol={{ span: 6 }}
                rules={[
                  { required: true, message: "Please enter your last name." },
                ]}
              >
                <Input />
              </Form.Item>
            </div>

            <Form.Item
              name="email"
              label="Email"
              labelCol={{ span: 3 }}
              rules={[
                { required: true, message: "Please enter your email." },
                { type: "email", message: "Please enter a valid email." },
              ]}
            >
              <Input disabled />
            </Form.Item>
          </div>

          <div className="md:flex md:gap-2">
            <div className="border border-gray-300 p-2 pt-4 pb-0 rounded-md mt-2 ">
              <Text className="text-sm font-medium">BILLING INFO</Text>
              <Form.Item
                className="mt-4"
                labelCol={{ span: 6 }}
                name="billingFullName"
                label="Full Name"
                rules={[{ required: true, message: "Please enter name." }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="billingMobile"
                label="Mobile No"
                labelCol={{ span: 6 }}
                rules={[
                  { required: true, message: "Please enter mobile number." },
                  { pattern: /^\d+$/, message: "Please enter a valid number!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="billingAddress"
                label="Address"
                labelCol={{ span: 6 }}
                rules={[
                  {
                    required: true,
                    message: "Please enter your billing address.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="billingCity"
                label=""
                labelCol={{ span: 6 }}
                rules={[
                  {
                    required: true,
                    message: "Please enter your billing city.",
                  },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  marginRight: "8px",
                }}
              >
                <Input placeholder="City" />
              </Form.Item>
              <Form.Item
                name="billingProvince"
                label=""
                labelCol={{ span: 6 }}
                rules={[
                  {
                    required: true,
                    message: "Please enter your billing province.",
                  },
                ]}
                style={{ display: "inline-block", width: "calc(50% - 4px)" }}
              >
                <Input placeholder="Province" />
              </Form.Item>
            </div>
            <div className="border border-gray-300 p-2 pt-4 pb-0 rounded-md mt-2 ">
              <div className="flex justify-between">
                <Text className="text-sm font-medium">DELIVERY INFO</Text>
                <ANTDButton
                  className="text-blue-800 border-blue-300"
                  size="small"
                  onClick={handleUseBillingInfo}
                >
                  Use Billing Info
                </ANTDButton>
              </div>
              <Form.Item
                className="mt-4"
                labelCol={{ span: 6 }}
                name="deliveryFullName"
                label="Full Name"
                rules={[{ required: true, message: "Please enter name." }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="deliveryMobile"
                label="Mobile No"
                labelCol={{ span: 6 }}
                rules={[
                  { required: true, message: "Please enter mobile number." },
                  { pattern: /^\d+$/, message: "Please enter a valid number!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="deliveryAddress"
                label="Address"
                labelCol={{ span: 6 }}
                rules={[
                  { required: true, message: "Please enter your Address." },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="deliveryCity"
                label=""
                labelCol={{ span: 6 }}
                rules={[{ required: true, message: "Please enter your City." }]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 4px)",
                  marginRight: "8px",
                }}
              >
                <Input placeholder="City" />
              </Form.Item>
              <Form.Item
                name="deliveryProvince"
                label=""
                labelCol={{ span: 6 }}
                rules={[
                  { required: true, message: "Please enter your Province." },
                ]}
                style={{ display: "inline-block", width: "calc(50% - 4px)" }}
              >
                <Input placeholder="Province" />
              </Form.Item>
            </div>
          </div>
          <div className="mt-5">
            <Button
              text="Submit"
              htmlType="submit"
              customStyles="w-full text-lg font-semibold py-7 rounded-lg"
              isLoading={isLoading || isLoadingSubmit}
            />
          </div>
        </UploadForm>
      </div>
    </Drawer>
  );
}

export default CustomerDetailsDrawer;
