import React, {useEffect} from "react";
import {BrowserRouter as Router, Routes, Route, useLocation, useNavigate} from "react-router-dom";

import Pages from "./pages";

import { ROUTE_TYPES } from "./config/routeTypes";
import { ROLES } from "./config/roles";

import ROUTES from "./data/ROUTES";

import RequireAuth from "./utils/helpers/auth/RequireAuth";
import ScrollToTop from "./utils/helpers/com/ScrollToTop";

import AuthContextProvider from "./auth/AuthContextProvider";
import { Auth } from "./auth/Auth";
import { authCfg } from "./api/api";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

interface AuthViewProps {
    children?: any
}

const AuthView: React.FC<AuthViewProps> = (props) => {
    const navigate = useNavigate();

    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get("code");

    const authenticate =async () => {
        if (!Auth.isSignedIn() && code) {
            const cfg = await authCfg();
            await Auth.signInWithToken(code, cfg.redirect_uri);
        }
    }

    useEffect(()=>{
        Auth.subscribe(async (state) =>{
            if (state){
                navigate('/');
            }
        });
    }, []);

    useEffect(()=>{
        authenticate().catch(e => console.error(e));
    }, [code]);

    return <>
        {props.children}
    </>

}

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <Router>
          <AuthView> 
            <ScrollToTop>
              <Routes>
                {ROUTES.map((route, index) => {
                  switch (route.type) {
                    case ROUTE_TYPES.Public:
                      return (
                        route.component && (
                          <Route
                            key={index}
                            path={route.path}
                            Component={route.component}
                          />
                        )
                      );

                    case ROUTE_TYPES.Protected:
                      return (
                        route.component && (
                          <Route key={index}>
                            <Route
                              element={
                                <RequireAuth
                                  allowedRoles={
                                    route.allowedRoles
                                      ? route.allowedRoles
                                      : [...Object.values(ROLES)]
                                  }
                                />
                              }
                            >
                              <Route
                                path={route.path}
                                Component={route.component}
                              />
                            </Route>
                          </Route>
                        )
                      );

                    default:
                      return (
                        <Route key={index} path="*" Component={Pages.Home} />
                      );
                  }
                })}
              </Routes>
            </ScrollToTop>
          </AuthView>
        </Router>
      </AuthContextProvider>
      {/* <ReactQueryDevtools  initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

export default App;
