import ExampleCard from "./_com/ExampleCard";

interface IExample {
  image?: any;
  title?: string;
  description?: string;
}

type ExampleListProps = {
  title?: string;
  data?: IExample[];
};

function ExampleList({ title, data }: ExampleListProps) {
  return (
    <div className=" bg-gray-200 bg-opacity-50">
      {title && (
        <div className="padding-x padding-y text-[35px] font-bold text-gray-800 text-center pb-5 md:pb-[40px]">
          {title}
        </div>
      )}

      <div>
        {data?.map((item, index) => {
          return (
            <ExampleCard
              key={index}
              index={index}
              image={item?.image}
              title={item?.title}
              description={item?.description}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ExampleList;
