import classNames from "classnames";
import { Col, Row } from "antd";

interface IExperiences {
  title: string;
}

const DATA_EXPERIENCES: IExperiences[] = [
  { title: "Solution Architecture" },
  { title: "Circuit Design" },
  { title: "PCB Design" },
  { title: "Enclosure Design" },

  { title: "PCB Fabrication" },
  { title: "PCB Assembly" },
  { title: "Prototyping" },
  { title: "BOM service" },

  { title: "Firmware Development" },
  { title: "Web Development" },
  { title: "UX/UI Design" },
  { title: "Cloud Solutions" },
];

type ExperienceCardProps = {
  title: string;
};

const ExperienceCard = ({ title }: ExperienceCardProps) => {
  return (
    <div
      className={classNames({
        "p-5 text-center border border-gray-300/0": true,
        "hover:cursor-pointer hover:bg-gray-200/80 hover:border-gray-300": true,
        "transition-primary": true,
      })}
    >
      <div className="text-lg font-semibold text-gray-600">{title}</div>
    </div>
  );
};

function Experience() {
  return (
    <div className="padding-x py-[50px] md:py-[80px] bg-boxes bg-cover bg-no-repeat bg-center">
      <div className="text-[35px] font-bold text-gray-800 text-center pb-10 md:pb-[80px]">
        We are experienced in
      </div>

      <Row gutter={10}>
        {DATA_EXPERIENCES.map((item, index) => {
          return (
            <Col md={6} className="w-full">
              <ExperienceCard key={index} title={item?.title} />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default Experience;
