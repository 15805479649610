// Hooks / Node modules / Styles
import DefaultLayout from "../../../layouts/DefaultLayout";

// Reducers / Actions

// Utils / Functions

// Components

// Sub-Components

// Data / Images / Icons

function Signup() {
  return (
    <DefaultLayout>
      <div>Signup</div>
    </DefaultLayout>
  );
}

export default Signup;
