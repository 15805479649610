import React from "react";
import { Radio } from "antd";
import type { RadioChangeEvent } from "antd";
import "./RadioCustom.css";

interface Option {
  label: string | number;
  value: string | number;
  image?: string;
  colored?: string;
}

interface RadioCustomProps {
  options: Option[];
  onChange?: (e: RadioChangeEvent) => void;
  variant?: "box" | "inline";
  disabledValues?: (string | number)[];
  value?: string | number;
  groupDefaultValue?: string | number;
}

const RadioCustom: React.FC<RadioCustomProps> = ({
  options,
  onChange,
  variant,
  disabledValues = [],
  value,
  groupDefaultValue,
}) => {
  return (
    <Radio.Group
      size="large"
      className="radio-custom"
      onChange={onChange}
      style={{
        marginTop: 0,
        maxWidth: 740,
      }}
      {...(groupDefaultValue !== undefined
        ? { defaultValue: groupDefaultValue }
        : {})}
      {...(value !== undefined ? { value } : {})}
    >
      {options.map((option) => (
        <Radio
          disabled={disabledValues.includes(option.value)}
          key={option.value}
          value={option.value}
          style={{
            marginTop: option.image || option.colored ? "12px" : "0px",
            maxWidth: "250px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: option.colored ? "row" : "column",
              alignItems: "center",
              minWidth: option.image
                ? "90px"
                : option.colored
                ? "60px"
                : "auto",
              minHeight: option.image
                ? "45px"
                : option.colored
                ? "auto"
                : "auto",
              padding: option.image || option.colored ? "3px" : "0px",
            }}
          >
            {option.image && (
              <img
                alt={option?.label?.toString()}
                width={40}
                height={40}
                src={option.image}
              />
            )}
            {option.colored && (
              <div
                style={{
                  height: 20,
                  width: 20,
                  backgroundColor: option.colored,
                  border: "1px solid #697c90",
                  borderRadius: "100%",
                  marginRight: "8px",
                }}
              />
            )}
            <span style={{ display: "inline-block" }}>{option.label}</span>
          </div>
        </Radio>
      ))}
    </Radio.Group>
  );
};

export default RadioCustom;
