import { AiOutlineCloudUpload } from "react-icons/ai";
import { GiReceiveMoney } from "react-icons/gi";
import { GiConfirmed } from "react-icons/gi";
import { BsBoxSeamFill } from "react-icons/bs";
import { ImCheckboxChecked } from "react-icons/im";
import { TbTruckDelivery } from "react-icons/tb";

import { StepList } from "../../../../../components";

interface IStep {
  icon?: any;
  title?: string;
  description?: string;
}

const DATA_STEPS: IStep[] = [
  {
    icon: <AiOutlineCloudUpload />,
    title: "Upload Files",
    description: "(BoM, Design, CPL)",
  },
  {
    icon: <GiReceiveMoney />,
    title: "Receive Quotation",
    description: "(within 48 hours)",
  },
  {
    icon: <GiConfirmed />,
    title: "Confirmation & Payment",
  },
  {
    icon: <BsBoxSeamFill />,
    title: "Source Components",
  },
  {
    icon: <ImCheckboxChecked />,
    title: "Assemble and Test",
  },
  {
    icon: <TbTruckDelivery />,
    title: "Deliver to doorstep",
  },
];

function Steps() {
  return (
    <div>
      <StepList title="Steps" data={DATA_STEPS} cols={6} />
    </div>
  );
}

export default Steps;
