function BOMOptions() {
  return (
    <div className="flex flex-col items-center justify-center gap-1 padding-x padding-y">
      <div className="inline-flex gap-3 text-[17px] text-gray-600">
        <div>Not sure where to start?</div>
        <a href="/" className="text-blue-500 font-semibold">
          See a sample BOM
        </a>
      </div>

      <div className="inline-flex gap-3 text-[17px] text-gray-600">
        <div>Need Help?</div>
        <a href="/contact-us" className="text-blue-500 font-semibold">
          Contact Us
        </a>
      </div>

      <div className="inline-flex gap-3 text-[17px] text-gray-600">
        <div>Do you want assembly</div>
        <a href="/" className="text-blue-500 font-semibold">
          Yes
        </a>
      </div>
    </div>
  );
}

export default BOMOptions;
