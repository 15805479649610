import React, { useState } from "react";
import { Form, notification } from "antd";
import { FormProps } from "antd/es/form/Form";

export interface UploadFormProps
  extends Omit<FormProps, "onFinish" | "disabled" | "form"> {
  onSubmit: (values: any) => Promise<any>;
  children?: React.ReactNode;
  initialValues?: object;
  setIsLoading: any;
  layout?: any;
  name: string;
  autoComplete?: string;
  labelCol?: any;
  wrapperCol?: any;
  labelWrap?: boolean;
  labelAlign?: any;
  form: any;
}

const UploadForm: React.FC<UploadFormProps> = (props: UploadFormProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onFinish = async () => {
    try {
      const values = await props.form.validateFields();
      setIsLoading(true);
      props.setIsLoading(true);

      window.onbeforeunload = function () {
        return false;
      };
      const res: boolean = await props.onSubmit(values);
      if (res) {
        props.form.resetFields();
        notification.info({ message: "Submitted" });
      } else {
        //  notification.error({ message: `Failed to Submit` });
      }
    } catch (e: any) {
      notification.error({ message: `Failed to Submit \n ${e.message}` });
    } finally {
      setIsLoading(false);
      props.setIsLoading(false);

      window.onbeforeunload = function () {};
    }
  };

  return (
    <Form
      form={props.form}
      initialValues={props.initialValues}
      onFinish={onFinish}
      disabled={isLoading}
      layout={props.layout}
      name={props.name}
      autoComplete={props.autoComplete}
      labelCol={props.labelCol}
      wrapperCol={props.wrapperCol}
      labelWrap={props.labelWrap}
      labelAlign={props.labelAlign}
    >
      {props.children}
    </Form>
  );
};

export default UploadForm;
