import { Button } from "../../../../../components";

function ContactRedirect() {
  return (
    <div className="padding-x py-[100px] md:py-[150px] flex flex-col md:flex-row items-center justify-center gap-10">
      <div className="text-section">Ready to make your mark in the world?</div>
      <Button text="Contact Us" to="/contact-us" />
    </div>
  );
}

export default ContactRedirect;
