import { AiOutlineCloudUpload } from "react-icons/ai";
import { GiReceiveMoney } from "react-icons/gi";
import { GiConfirmed } from "react-icons/gi";
import { TbTruckDelivery } from "react-icons/tb";

import { StepList } from "../../../../../components";

interface IStep {
  icon?: any;
  title?: string;
  description?: string;
}

const DATA_STEPS: IStep[] = [
  {
    icon: <AiOutlineCloudUpload />,
    title: "Upload BOM",
  },
  {
    icon: <GiReceiveMoney />,
    title: "Receive Quotation",
    description: "(within 48 hours)",
  },
  {
    icon: <GiConfirmed />,
    title: "Confirmation & Payment",
  },
  {
    icon: <TbTruckDelivery />,
    title: "Delivered to doorstep",
    description: "(within 7-14 working days from payment)",
  },
];

function Steps() {
  return (
    <div>
      <StepList title="Steps" data={DATA_STEPS} />
    </div>
  );
}

export default Steps;
