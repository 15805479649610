import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button as AntButton,
  notification,
  Alert,
  Modal,
} from "antd";
import { bomCplValidation, getQuotation } from "../../../../../../api/api";
import UploadForm from "../../../../../../utils/helpers/UploadForm";
import GerberUploadArea from "./_com/gerber-upload-area/GerberUploadArea";
import AdvancedOptions from "./_com/advanced-options/AdvancedOptions";
import OtherOptions from "./_com/other-options/OtherOptions";
import { Form, Typography } from "antd";
import CustomerDetailsDrawer from "./_com/next-drawer/ChargeDetails";
import PCBForm from "./_com/pcbForm";
import { useMutation, useQueryClient } from "react-query";

const INITIAL_DATA = {
  type: "mm",
  differentDesignInPanelType: 1,
  layersCount: "2 Layer",
  materialType: "FR-4",
  FR4Tg: "TG130",
  thicknessValue: 1.6,
  minTrackSpacing: "6/6mil",
  minHoleSize: "0.3mm",
  solderMask: "Green",
  silkscreen: "White",
  surfaceFinish: "HASL with lead",
  viaProcess: "Tenting vias",
  finishedCopper: "1 oz Cu",
};

const requiredFields = [
  "differentDesignInPanelType",
  "finishedCopper",
  "layersCount",
  "materialType",
  "minHoleSize",
  "minTrackSpacing",
  "quantityNumber",
  "silkscreen",
  "size",
  "solderMask",
  "surfaceFinish",
  "thicknessValue",
  "viaProcess",
  "FR4Tg",
];

interface QuotationData {
  pcbCost: number;
  shippingCost: number;
  totalCost: number;
  assemblyPrice: {
    setup_cost: number;
    feeder_cost: number;
    stencil_cost: number;
    pcb_panal_cost: number;
    smd_assembly_cost: number;
    tht_assembly_cost: number;
    total_assembly_cost: number;
  };
}

function PcbFabricationForm() {
  const [quotationData, setQuotationData] = useState<QuotationData | null>(
    null
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [formData, setFormData] = useState<any>(null);

  const pcbData = new FormData();
  const { Text } = Typography;
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const watchedValues = Form.useWatch([], form);

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };
  const { mutate, isLoading: isLoadingPrice } = useMutation(getQuotation, {
    onSuccess: (data) => {
      setQuotationData(data);
    },
    onError: () => {
      notification.error({
        message: "Error Occurred",
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });
  const { mutate: bomCplwarn, isLoading: isLoadingBomCpl } = useMutation(
    bomCplValidation,
    {
      onSuccess: (data) => {
        if (!data.isValid) {
          Modal.warning({
            title: `${data.message || ""} `,
            content: `Do you want to proceed to the next step?`,
            onOk: () => {
              toggleDrawer();
            },
          });
        } else if (data.isValid) {
          toggleDrawer();
        }
      },
      onError: () => {
        notification.error({
          message: "Error Occurred",
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries("create");
      },
    }
  );

  const watchedFields = Form.useWatch(requiredFields, form);
  const currentValues = form.getFieldsValue(true);
  const allFieldsFilled = requiredFields.every(
    (field) =>
      currentValues[field] !== undefined &&
      currentValues[field] !== null &&
      currentValues[field] !== ""
  );

  useEffect(() => {
    const checkFieldsAndSubmit = async () => {
      const assemblyData = {
        bom_data: {
          components: form.getFieldValue("assemblyData"),
        },
        no_of_sides: form.getFieldValue("sidesToAssemble") === "Both" ? 2 : 1,
      };

      if (allFieldsFilled) {
        let quotationData = {
          Length: parseFloat(currentValues.length) || 1,
          Width: parseFloat(currentValues.size) || 1,
          Layers: parseInt(currentValues.layersCount, 10) || 1,
          Qty: parseInt(currentValues.quantityNumber, 10) || 5,
          Thickness: parseFloat(currentValues.thicknessValue) || 1.6,
          Material: currentValues.materialType || "FR-4",
          DesignInPanel:
            parseInt(currentValues.differentDesignInPanelType, 10) || 1,
          MinTrackSpacing: currentValues.minTrackSpacing || "6/6mil",
          MinHoleSize: parseFloat(currentValues.minHoleSize) || 0.3,
          SolderMask: currentValues.solderMask,
          Silkscreen: currentValues.silkscreen,
          SurfaceFinish: currentValues.surfaceFinish || "HASL with lead",
          ViaProcess: currentValues.viaProcess || "Tenting vias",
          FinishedCopper: currentValues.finishedCopper || "1 oz Cu",
          FR4Tg: currentValues.FR4Tg || "TG130",
          Goldfingers: "No",
          assemblyData: form.getFieldValue("assemblyData")
            ? assemblyData
            : null,
        };

        console.log("value", form.getFieldValue("sidesToAssemble"));

        try {
          await mutate(quotationData);

          setFormData(quotationData);
        } catch (error) {
          console.error("Error submitting quotation:", error);
        }
      }
    };

    checkFieldsAndSubmit();
  }, [form, mutate, watchedFields, allFieldsFilled, currentValues]);

  const assemblyOrderdata = {
    bom_data: {
      components: form.getFieldValue("assemblyData"),
    },
    no_of_sides: form.getFieldValue("sidesToAssemble") === "Both" ? 2 : 1,
    sides_to_assemble: form.getFieldValue("sidesToAssemble"),
    cplId: form.getFieldValue("cplId"),
    bomId: form.getFieldValue("bomFileId"),
  };

  const onSubmit = async (values: any) => {
    console.log(form.getFieldsValue(), "value");
    if (form.getFieldValue("assemblyData")) {
      try {
        await bomCplwarn({
          cplFileUrl: `https://incbotic-gerberviwe.s3.ap-south-1.amazonaws.com/cpl/${form.getFieldValue(
            "cplId"
          )}.csv`,
          bomFileUrl: `https://incbotic-gerberviwe.s3.ap-south-1.amazonaws.com/bom/${form.getFieldValue(
            "bomFileId"
          )}.csv`,
        });
      } catch (error) {
        console.error("Error submitting quotation:", error);
      }
    } else {
      toggleDrawer();
    }
  };

  return (
    <div className="my-4 ">
      <UploadForm
        form={form}
        initialValues={INITIAL_DATA}
        onSubmit={onSubmit}
        setIsLoading={setIsLoading}
        name="pcbFabricationForm"
        autoComplete="off"
        labelCol={{ span: 7 }}
        labelWrap={true}
        labelAlign={"left"}
      >
        <Row justify={"center"}>
          <Col
            flex={8}
            style={{ marginBottom: 20, marginRight: 8, marginLeft: 16 }}
          >
            <Card>
              <div>
                <Form.Item colon={false} name="assemblyData" />

                <GerberUploadArea form={form} />
                <PCBForm form={form} />
                <AdvancedOptions />
              </div>
            </Card>
            <Card style={{ marginTop: 16, marginBottom: 5 }}>
              <OtherOptions pcbForm={form} />
            </Card>
          </Col>

          {/* Right */}
          <Col flex={2} style={{ marginRight: 16, marginLeft: 8 }}>
            <div className="sticky top-20">
              <Form.Item wrapperCol={{ span: 24 }}>
                <Card style={{ width: "100%" }}>
                  <div style={{ marginBottom: 10 }}>
                    <Text strong={true}>Charge Details</Text>
                  </div>
                  <div
                    style={{
                      marginBottom: 8,
                      paddingBottom: 8,
                    }}
                  >
                    <Card style={{ minWidth: 220 }} loading={isLoadingPrice}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text>PCB Cost</Text>
                        <span>
                          Rs{" "}
                          {(quotationData && quotationData?.pcbCost) || "0.00"}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text>Assembly Cost</Text>
                        <span>
                          Rs{" "}
                          {(quotationData &&
                            quotationData?.assemblyPrice
                              ?.total_assembly_cost) ||
                            "0.00"}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginBottom: 4,
                        }}
                      >
                        <Text>Shipping Cost</Text>
                        <span>
                          Rs{" "}
                          {(quotationData && quotationData?.shippingCost) ||
                            "0.00"}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          borderTop: "1px solid #f1f3f6",
                          marginTop: 4,
                          paddingTop: 4,
                        }}
                      >
                        <Text strong>Total Cost</Text>
                        <Text strong type="warning">
                          Rs{" "}
                          {(quotationData && quotationData?.totalCost) ||
                            "0.00"}
                        </Text>
                      </div>
                    </Card>
                  </div>

                  <AntButton
                    type="primary"
                    shape="round"
                    htmlType="submit"
                    disabled={isLoadingPrice}
                    loading={isLoadingBomCpl}
                    className="w-full text-lg font-semibold py-7 rounded-lg"
                  >
                    Next
                  </AntButton>
                </Card>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </UploadForm>
      {drawerVisible && quotationData && (
        <CustomerDetailsDrawer
          toggleDrawer={toggleDrawer}
          formData={formData}
          pcbForm={form}
          onClose={toggleDrawer}
          quotationData={quotationData}
          assemblyOrderdata={assemblyOrderdata}
          spcialReq={form.getFieldValue("specialRequirements")}
        />
      )}
    </div>
  );
}

export default PcbFabricationForm;
