// Hooks / Node modules / Styles
import DefaultLayout from "../../layouts/DefaultLayout";

// Reducers / Actions

// Utils / Functions

// Components

// Sub-Components
import Hero from "./_com/Hero";
import Description from "./_com/Description";
import WhyIncbotic from "./_com/WhyIncbotic";
import ContactUs from "./_com/ContactUs";

// Data / Images / Icons

function Careers() {
  return (
    <DefaultLayout>
      <div>
        <Hero/>
        <Description />
        <WhyIncbotic />
        <ContactUs />
      </div>
    </DefaultLayout>
  );
}

export default Careers;
