import { ExampleList } from "../../../../../components";

import { iot_1, iot_2, iot_3 } from "../../../../../assets";

interface IExample {
  image?: any;
  title?: string;
  description?: string;
}

const DATA: IExample[] = [
  {
    image: iot_1,
    title: "Enhanced Connectivity",
    description:
      "IoT enables smart homes, connected vehicles, and smart cities, transforming the way we live and work.",
  },
  {
    image: iot_2,
    title: "Efficient Data Collection",
    description:
      " IoT solutions provide real-time data insights for informed decisions and improved efficiency.",
  },
  {
    image: iot_3,
    title: "Improved Sustainability",
    description:
      "IoT promotes resource efficiency, waste reduction, and sustainable practices for a greener future.",
  },
];

function Examples() {
  return (
    <div>
      <ExampleList data={DATA} />
    </div>
  );
}

export default Examples;
