import { AiOutlineCloudUpload } from "react-icons/ai";
import { GiReceiveMoney } from "react-icons/gi";
import { GiConfirmed } from "react-icons/gi";
import { MdDesignServices } from "react-icons/md";
import { TbTruckDelivery } from "react-icons/tb";

import { StepList } from "../../../../../components";

interface IStep {
  icon?: any;
  title?: string;
  description?: string;
}

const DATA_STEPS: IStep[] = [
  {
    icon: <AiOutlineCloudUpload />,
    title: "Upload Files",
    description: "(Sketch, Schematic Diagram or Contact us for help)",
  },
  {
    icon: <GiReceiveMoney />,
    title: "Receive Quotation",
    description: "(within 48 hours)",
  },
  {
    icon: <GiConfirmed />,
    title: "Confirmation & Payment",
  },
  {
    icon: <MdDesignServices />,
    title: "Designing",
  },
  {
    icon: <TbTruckDelivery />,
    title: "Gerber File Delivery",
  },
];

function Steps() {
  return (
    <div>
      <StepList title="Steps" data={DATA_STEPS} cols={4} />
    </div>
  );
}

export default Steps;
