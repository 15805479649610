// Hooks / Node modules / Styles
import { useState } from "react";
import * as yup from "yup";
import JSZip from "jszip";
import UploadForm from "../../../../../../utils/helpers/UploadForm";
import { uploadPCBAssembly } from "../../../../../../api/api";

// Reducers / Actions

// Utils / Functions

// Components
import { Button } from "../../../../../../components";

// Sub-Components
import BOMUploadArea from "./file-uploads/BOMUploadArea";
import GerberUploadArea from "./file-uploads/GerberUploadArea";
import CPLUploadArea from "./file-uploads/CPLUploadArea";
import OrderOptions from "./order-options/OrderOptions";
import { Form } from "antd";

// Data / Images / Icons
const INITIAL_DATA = {
  // order options
  componentSourcing: "customer",
  pcb: "customer",
  uploadFirmware: "yes",
};

let schema = yup.object().shape({
  bom: yup
    .mixed()
    .test(
      "isGerber",
      "Invalid file format. Please upload a Gerber file.",
      (value: any) => {
        if (!value) return true; // Allow an empty value
        return value[0]?.type === "application/zip";
      }
    )
    .required("File is required"),
  gerber: yup
    .mixed()
    .test(
      "isGerber",
      "Invalid file format. Please upload a Gerber file.",
      (value: any) => {
        if (!value) return true; // Allow an empty value
        return (
          value[0]?.type === "application/zip" ||
          value[0]?.type === "application/x-zip-compressed" ||
          value[0]?.type === "application/x-rar-compressed"
        );
      }
    )
    .required("File is required"),
  cpl: yup
    .mixed()
    .test(
      "isCPL",
      "Invalid file format. Please upload a CPL file.",
      (value: any) => {
        if (!value) return true; // Allow an empty value
        return (
          value[0]?.type === "application/zip" ||
          value[0]?.type === "application/x-zip-compressed" ||
          value[0]?.type === "application/x-rar-compressed"
        );
      }
    )
    .required("File is required"),
});

const yupSync = {
  async validator({ field }: any, value: any) {
    await schema.validateSyncAt(field, { [field]: value });
  },
};

function PcbAssemblyForm() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const onSubmit = async (values: any) => {
    const file_gerber: any = values.gerber[0];
    const file_bom: any = values.bom[0];
    const file_cpl: any = values.cpl[0];
    if (file_gerber && file_bom && file_cpl) {
      const zip = new JSZip();
      zip.file(file_gerber.name, file_gerber.originFileObj);
      zip.file(file_bom.name, file_bom.originFileObj);
      zip.file(file_cpl.name, file_cpl.originFileObj);
      const zipFile = await zip.generateAsync({ type: "blob" });

      const formData = new FormData();
      formData.append("componentSourcing", values.componentSourcing);
      formData.append("pcb", values.pcb);
      formData.append("uploadFirmware", values.uploadFirmware);
      formData.append("file", zipFile);

      await uploadPCBAssembly(formData);

      return true;
    }
    return false;
  };

  return (
    <div className="bg-white">
      <UploadForm
        form={form}
        initialValues={INITIAL_DATA}
        onSubmit={onSubmit}
        setIsLoading={setIsLoading}
        name="pcbDesignForm"
        autoComplete="off"
        layout="vertical"
      >
        <div className="padding-x padding-y">
          <div className="text-[35px] font-bold text-gray-600 text-center pb-5 md:pb-[40px]">
            Upload Files
          </div>
          <div className="pb-5">
            <BOMUploadArea rules={[yupSync]} />
          </div>
          <div className="pb-5">
            <GerberUploadArea rules={[yupSync]} />
          </div>
          <div className="pb-5">
            <CPLUploadArea rules={[yupSync]} />
          </div>
          <OrderOptions />

          <Button
            text="Submit"
            htmlType="submit"
            customStyles="w-full md:w-[200px] text-lg font-semibold py-7 rounded-lg mx-auto"
            isLoading={isLoading}
          />
        </div>
      </UploadForm>
    </div>
  );
}

export default PcbAssemblyForm;
