import React, { useState, useEffect, useContext } from "react";
import {
  Pagination,
  Card,
  Row,
  Col,
  Typography,
  Tag,
  Divider,
  Spin,
  notification,
} from "antd";
import { AuthContext } from "../../auth/AuthContextProvider";
import DefaultLayout from "../../layouts/DefaultLayout";
import { SignIn } from "../../auth/AuthComponents";
import axios from "axios";
import bottomImg from "./history.png";

const { Title, Text } = Typography;

interface Order {
  order_id: string;
  customer_info: {
    email: string;
    last_name: string;
    first_name: string;
    billing_info: {
      mobile: string;
      address: {
        city: string;
        province: string;
        first_line: string;
      };
      full_name: string;
    };
    delivery_info: {
      mobile: string;
      address: {
        city: string;
        province: string;
        first_line: string;
      };
      full_name: string;
    };
  };
  status: string;
  created_on: string;
  updated_on: string;
  invoice_number: string;
  pcbCost?: number | string;
  shippingCost?: number | string;
  componentPrice?: number | string | null;
  assemblyPrice?: {
    setup_cost: number | string;
    feeder_cost: number | string;
    stencil_cost: number | string;
    pcb_panal_cost: number | string;
    smd_assembly_cost: number | string;
    tht_assembly_cost: number | string;
    total_assembly_cost: number | string;
  } | null;
}

const statusColorMap: { [key: string]: string } = {
  pending: "orange",
  accepted: "green",
  declined: "red",
  delivered: "blue",
};

function OrderHistory() {
  const [currentPage, setCurrentPage] = useState(1);
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  const pageSize = 10;

  const auth = useContext(AuthContext);

  useEffect(() => {
    if (auth) {
      fetchOrders();
    }
  }, [auth]);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/api/v1/customer_order_history", {
        params: { customer_email: auth.user?.email },
      });
      if (response.data.message === "success") {
        setOrders(response.data.orders);
      } else {
        notification.error({ message: "Failed to fetch order history" });
      }
    } catch (error) {
      notification.error({
        message: "An error occurred while fetching order history",
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const currentData = orders.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const formatCost = (cost: number | string | undefined | null): string => {
    if (typeof cost === "number") {
      return cost.toFixed(2);
    } else if (typeof cost === "string") {
      const parsed = parseFloat(cost);
      return isNaN(parsed) ? "0.00" : parsed.toFixed(2);
    } else {
      return "0.00";
    }
  };

  const calculateTotalCost = (order: Order) => {
    let total =
      parseFloat(formatCost(order.pcbCost)) +
      parseFloat(formatCost(order.shippingCost));
    if (order.assemblyPrice) {
      total += parseFloat(formatCost(order.assemblyPrice.total_assembly_cost));
    }
    return total.toFixed(2);
  };

  return (
    <DefaultLayout>
      <div className="p-4">
        {auth.loggedIn ? (
          <>
            <Title
              level={4}
              style={{ marginBottom: "20px", marginLeft: "140px" }}
            >
              Order History
            </Title>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <Spin size="large" />
              </div>
            ) : (
              <>
                <Row gutter={[16, 16]} justify="center">
                  {currentData.map((order) => (
                    <Col xs={24} key={order.order_id}>
                      <Card
                        hoverable
                        style={{
                          borderRadius: "10px",
                          marginBottom: "16px",
                          height: "auto",
                          width: "80%",
                          margin: "0 auto",
                        }}
                      >
                        <div style={{ marginBottom: "16px" }}>
                          <Row gutter={16} align="middle">
                            <Col span={8}>
                              <Text strong>Order Number: </Text>
                              <Text>{order.invoice_number}</Text>
                            </Col>
                            <Col span={8}>
                              <Text strong>Date Placed: </Text>
                              <Text>
                                {new Date(
                                  order.created_on
                                ).toLocaleDateString()}
                              </Text>
                            </Col>
                            <Col span={8}>
                              <Text strong>Status: </Text>
                              <Tag
                                color={
                                  statusColorMap[order.status.toLowerCase()]
                                }
                              >
                                {order.status}
                              </Tag>
                            </Col>
                          </Row>
                        </div>
                        <Divider style={{ margin: "12px 0" }} />
                        <Row gutter={16}>
                          <Col span={8}>
                            <img
                              src={bottomImg}
                              alt="PCB"
                              style={{
                                width: "80%",
                                height: "auto",
                                objectFit: "cover",
                                marginLeft: "25px",
                              }}
                            />
                          </Col>
                          <Col span={16}>
                            <Row>
                              <Col span={12}>
                                <div
                                  style={{
                                    marginBottom: "12px",
                                    marginRight: "25px",
                                  }}
                                >
                                  <Text strong>Shipping Address </Text>
                                  <div style={{ marginTop: "6px" }}>
                                    <Text>{`${order.customer_info.delivery_info.address.first_line}, ${order.customer_info.delivery_info.address.city}, ${order.customer_info.delivery_info.address.province}`}</Text>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    marginBottom: "12px",
                                    marginRight: "25px",
                                  }}
                                >
                                  <Text strong>Billing Address </Text>
                                  <div style={{ marginTop: "6px" }}>
                                    <Text>{`${order.customer_info.billing_info.address.first_line}, ${order.customer_info.billing_info.address.city}, ${order.customer_info.billing_info.address.province}`}</Text>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    marginBottom: "12px",
                                    marginRight: "25px",
                                  }}
                                >
                                  <Text strong>Customer Name: </Text>
                                  <Text>{`${order.customer_info.first_name} ${order.customer_info.last_name}`}</Text>
                                </div>
                                <div
                                  style={{
                                    marginBottom: "12px",
                                    marginRight: "25px",
                                  }}
                                >
                                  <Text strong>Billing Mobile: </Text>
                                  <Text>
                                    {order.customer_info.billing_info.mobile}
                                  </Text>
                                </div>
                                <div
                                  style={{
                                    marginBottom: "12px",
                                    marginRight: "25px",
                                  }}
                                >
                                  <Text strong>Delivery Mobile: </Text>
                                  <Text>
                                    {order.customer_info.delivery_info.mobile}
                                  </Text>
                                </div>
                              </Col>
                              <Col span={12}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginBottom: "8px",
                                    marginRight: "25px",
                                  }}
                                >
                                  <Text strong>Fabrication Cost:</Text>
                                  <Text>LKR {formatCost(order.pcbCost)}</Text>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginBottom: "8px",
                                    marginRight: "25px",
                                  }}
                                >
                                  <Text strong>Shipping Cost:</Text>
                                  <Text>
                                    LKR {formatCost(order.shippingCost)}
                                  </Text>
                                </div>
                                {order.assemblyPrice && (
                                  <>
                                    <div
                                      style={{
                                        marginBottom: "8px",
                                        marginRight: "25px",
                                      }}
                                    >
                                      <Text strong>Assembly Cost:</Text>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginBottom: "2px",
                                        marginRight: "25px",
                                        marginLeft: "9px",
                                      }}
                                    >
                                      <Text>Setup Cost:</Text>
                                      <Text>
                                        LKR{" "}
                                        {formatCost(
                                          order.assemblyPrice.setup_cost
                                        )}
                                      </Text>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginBottom: "2px",
                                        marginRight: "25px",
                                        marginLeft: "9px",
                                      }}
                                    >
                                      <Text>Feeder Cost:</Text>
                                      <Text>
                                        LKR{" "}
                                        {formatCost(
                                          order.assemblyPrice.feeder_cost
                                        )}
                                      </Text>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginBottom: "2px",
                                        marginRight: "25px",
                                        marginLeft: "9px",
                                      }}
                                    >
                                      <Text>Stencil Cost:</Text>
                                      <Text>
                                        LKR{" "}
                                        {formatCost(
                                          order.assemblyPrice.stencil_cost
                                        )}
                                      </Text>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginBottom: "2px",
                                        marginRight: "25px",
                                        marginLeft: "9px",
                                      }}
                                    >
                                      <Text>PCB Panel Cost:</Text>
                                      <Text>
                                        LKR{" "}
                                        {formatCost(
                                          order.assemblyPrice.pcb_panal_cost
                                        )}
                                      </Text>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginBottom: "2px",
                                        marginRight: "25px",
                                        marginLeft: "9px",
                                      }}
                                    >
                                      <Text>SMD Assembly Cost:</Text>
                                      <Text>
                                        LKR{" "}
                                        {formatCost(
                                          order.assemblyPrice.smd_assembly_cost
                                        )}
                                      </Text>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginBottom: "2px",
                                        marginRight: "25px",
                                        marginLeft: "9px",
                                      }}
                                    >
                                      <Text>THT Assembly Cost:</Text>
                                      <Text>
                                        LKR{" "}
                                        {formatCost(
                                          order.assemblyPrice.tht_assembly_cost
                                        )}
                                      </Text>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginBottom: "4px",
                                        marginRight: "25px",
                                        marginLeft: "9px",
                                      }}
                                    >
                                      <Text>Component Cost:</Text>
                                      <Text>
                                        LKR {formatCost(order.componentPrice)}
                                      </Text>
                                    </div>
                                  </>
                                )}
                                <Divider style={{ margin: "12px 0" }} />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginBottom: "8px",
                                    marginRight: "25px",
                                  }}
                                >
                                  <Text strong>Total Cost:</Text>
                                  <Text strong>
                                    LKR {calculateTotalCost(order)}
                                  </Text>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Divider style={{ margin: "12px 0" }} />
                      </Card>
                    </Col>
                  ))}
                </Row>
                <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  total={orders.length}
                  onChange={handlePageChange}
                  style={{
                    textAlign: "center",
                    marginTop: "16px",
                  }}
                />
              </>
            )}
          </>
        ) : (
          <SignIn />
        )}
      </div>
    </DefaultLayout>
  );
}

export default OrderHistory;
