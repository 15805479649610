import classNames from "classnames";
import { Col, Row } from "antd";

interface IServices {
  title: string;
}

const DATA_SERVICES: IServices[] = [
  { title: "Soldering THT, SMD, SMT, BGA" },
  { title: "Quality control" },
  { title: "Functional testing" },
];

type ServiceCardProps = {
  title: string;
};

const ServiceCard = ({ title }: ServiceCardProps) => {
  return (
    <div
      className={classNames({
        "p-5 text-center border border-gray-300/0 bg-gray-300/50 border-gray-300 w-full md:w-full": true,
        "hover:cursor-pointer hover:bg-gray-200/80 hover:border-gray-400": true,
        "transition-primary": true,
      })}
    >
      <div className="text-lg font-semibold text-gray-600">{title}</div>
    </div>
  );
};

function Services() {
  return (
    <div className="padding-x py-[50px] md:py-[80px] bg-bg_calm_splines bg-cover bg-no-repeat bg-center">
      <div className="text-[35px] font-bold text-gray-800 text-center pb-5 md:pb-[30px]">
      Our Services include:
      </div>

      <Row gutter={10} className="items-center justify-center">
        {DATA_SERVICES.map((item, index) => {
          return (
            <Col md={8} className="w-full">
              <ServiceCard key={index} title={item?.title} />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default Services;
